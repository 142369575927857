import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { constant } from "../app.constants";
import { CommonService } from "./common.service";

const reqOptns = {
    headers: new HttpHeaders().set("isLoadNotReq", "true")
}
@Injectable({
    providedIn: "root"
})
export class UserService {
    userPrivs$: Observable<any>;
    _userPrivs$: BehaviorSubject<any>;

    sessionTimeout$: Observable<any>;
    _sessionTimeout$: BehaviorSubject<any>;

    _userActionOccured: Subject<void> = new Subject();

    _idleTimeout: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;

    constructor(private _http: HttpClient, private _commonService: CommonService) {
        this._userPrivs$ = new BehaviorSubject({}) as BehaviorSubject<any>; // value from child components
        this.userPrivs$ = this._userPrivs$.asObservable();
        this._sessionTimeout$ = new BehaviorSubject("") as BehaviorSubject<any>; // value from child components
        this.sessionTimeout$ = this._sessionTimeout$.asObservable();

    }

    getAccessToken(): string {
        return this.getToken("access_token");
    }

    getToken(key): string {
        if (key === 'access_token') {
            const ca: Array<string> = document.cookie.split(";");
            const caLen: number = ca.length;
            const cookieName = `${key}=`;
            let c: string;
            for (let i = 0; i < caLen; i += 1) {
                c = ca[i].replace(/^\s+/g, "");
                if (c.indexOf(cookieName) === 0) {
                    return this._commonService.DecryptData(c.substring(cookieName.length, c.length));
                }
            }
        }
        else{
            let value = localStorage.getItem(key);
            if(value) {
                let data =  this._commonService.DecryptData(value);
                return data;
            }
        }
        return "";
    }

    getUserPrivs(userId: number): void {
        this._http
            .get<any>(constant.GET_USER_Privs.replace("{userid}", "" + userId))
            .subscribe(resp => this.storeUSerPrivsInBS(resp));
    }

    getBatchedRxs(post): Observable<any> {
        return this._http.post<any>(constant.POST_SFAX_BATCHEDRXS,post);
    }

    getUserPrivsToSubs(userId: number): Observable<number[]> {
        return this._http.get<number[]>(
            constant.GET_USER_Privs.replace("{userid}", "" + userId)
        );
    }

    getUserPrivsFromCookies(): any {
      // const ca: Array<string> = document.cookie.split(";");
        // const caLen: number = ca.length;
        // const cookieName = `${"Privs"}=`;
        // let c: string;
        if (localStorage.getItem("Privs")) {
            const data = this._commonService.DecryptData(localStorage.getItem("Privs"));
            return  data ? JSON.parse(data) : "";
        }

        // for (let i = 0; i < caLen; i += 1) {
        //     c = ca[i].replace(/^\s+/g, "");
        //     if (c.indexOf(cookieName) === 0) {
        //         return JSON.parse(
        //             c.substring(cookieName.length, c.length).replace(/\\/g, "")
        //         );
        //     }
        // }
        return "";
    }

    storeUSerPrivsInBS(userPrivs: number[]) {
        this._userPrivs$.next(userPrivs);
    }
    sessionTimeoutFun(data) {
        this._sessionTimeout$.next(data);
    }

    Login(loginData: any): Observable<any> {
        // let body = new HttpParams()
        //     .set("PharmUsername", loginData.PharmUsername)
        //     .set("PharmPassword", loginData.PharmPassword)
        //     .set("grant_type", "password");
        // // for (const key in loginData) {
        // //     if (loginData.hasOwnProperty(key)) {
        // //         body = body.set(key, loginData[key]);
        // //     }
        // // }

        // let headers = new HttpHeaders();
        // headers = headers.set(
        //     "Content-Type",
        //     "application/x-www-form-urlencoded"
        // );
        // headers = headers.set("subDomain", "localhost:90");
        // return this._http.post<any>(
        //     constant.POST_Login_Login,
        //     body.toString(),
        //     {
        //         headers: headers
        //     }
        // );
        loginData["subDomain"] = "localhost:90";
        return this._http.post<any>(constant.POST_Login_Login, loginData, reqOptns);
    }

    Logout(userid: any): Observable<any> {
        return this._http.put<any>(
            constant.PUT_Login_Logout.replace("{id}", "" + userid),
            {}
        );
    }

    checkPrivCheck(value) {
        return this._http.post(constant.POST_Priv_Check, value);
    }

    postFile(endpoint, uploadData: any): any {
        let formInfo = [];
        // uploadData.map(data => {
        const formData: FormData = new FormData();
        formData.append("fileKey", uploadData, uploadData.name);
        // formInfo.push(formData);
        // });

        // const formData3: FormData = new FormData();
        // formData3.append("fileKey", formData2[1], formData2[1].name);
        // formInfo.push(formData3);

        return this._http.post<any>(endpoint, formData);
    }

    get userActionOccured(): Observable<void> {
        return this._userActionOccured.asObservable();
    }

    notifyUserAction() {
        this._userActionOccured.next();
    }

    isIdleTimedOut(): Observable<any> {
        return this._idleTimeout.asObservable();
    }

    notifyIdleTimedOut(value) {
        this._idleTimeout.next(value);
    }

    removeIdleTimeOut() {
        localStorage.removeItem("ExpireTime");
    }

    getErrorLogs(logFiltrParms: any): Observable<any> {
        return this._http.post<any>(constant.POST_SHARED_ERRORLOG, logFiltrParms);
    }

    sendSfaxRequest(reqParams: any): Observable<any> {
        return this._http.post<any>(constant.POST_SFAX_REQUEST, reqParams);
    }

    updateFaxNoToPresc(params: any): Observable<any> {
        return this._http.put<any>(constant.PUT_UPDATEFAXNO_TOPRESC, params);
    }

    printLocally(params:any): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        // headers = headers.set("isLoadNotReq", "true");
        return this._http.post(constant.POST_PRINT_LOCALLY,
            params,{ headers: headers, responseType: "blob" });
    }


     deleteRx(params:any):Observable<any>  {
        return this._http.put<any>(constant.PUT_FAX_BATCHSTATUS,params);
    }

    sendAll():Observable<any> {
        return this._http.post<any>(constant.POST_FAX_PROCESSBATCH,{});
    }

    emptyBatch():Observable<any>{
        return this._http.put<any>(constant.PUT_BATCHDELETE,{});
    }

    followUpLog(params):Observable<any> {
        return this._http.post<any>(constant.POST_FAX_FOLLOWUPLOG,params);
    }

    printRxForm(payload) {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Rx_FORM_REPORT,
            payload,{ headers: headers, responseType: "blob" });
    }

    getWijimoAndDWTDWTLicenseKey(): Observable<any> {
        return this._http.get<any>(
            constant.get_Wijimo_DWTKey
        );

    }
    getReleaseNotes(pageId : string){
        return this._http.get<any>(constant.GET_ReleaseNotes.replace("{pageId}","" + pageId));
    }
    getReleaseNotesPages(date){
        return this._http.post<any>(constant.GET_ReleaseNotesPages,date)
    }
    postReleaseNotesBuildVersion(buildInfo : any):Observable<any>{
        return this._http.post<any>(constant.POST_ReleaseNotesBuildVersion,buildInfo)
    }
    getBuildVersion(addHdrs?:boolean){
        if (addHdrs) {
            return this._http.get<any>(constant.GET_BuildVersion, reqOptns);
        } else {
            return this._http.get<any>(constant.GET_BuildVersion);
        }
    }

    getSSORequestData(){
        return this._http.post<any>(constant.POST_SSOREQUEST, null)
    }

}
