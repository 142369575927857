import { UserService } from "./../../../services/user.service";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Component, OnInit, Input, ViewChild, Output, EventEmitter, HostListener } from "@angular/core";
import { AccCategoryE, AuditLog, Note, NotesLog, searchTrackPatientCriteria, SettingsE } from "../../../models";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import * as _ from "lodash";
import { AlertService, CommonService, DrugService, NotesLogService, RxService } from "../../../services";
import { PrivMaskGuard } from "src/app/guards";
import { MsgConfig } from "src/app/app.messages";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { CommonHeaders } from "src/app/models/labels.models";
import { NotesMainCat } from "src/app/models/notes.enum";

@Component({
    selector: "app-override-log",
    templateUrl: "./override-log.component.html"
})
export class OverrideLogComponent implements OnInit {
    auditObj: AuditLog = new AuditLog();
    notesLogFG: FormGroup;

    OverrideLogFG: any;
    privMask: any;
    catId: any;
    modal: any;
    isConsolidated:boolean = false;
    unsubscribe$: Subject<void> = new Subject();

    @ViewChild("OverRide", { static: true })
    OverRide: any;


    @ViewChild("Comments", { static: true })
    Comments: any;

    @ViewChild("OverRideW", { static: true })
    OverRidew: any;
    errorMs: string;
    OverRideCmntFG: any;
    isComp: any;
    CommentsOpen: boolean;
    overrideObject = {
        overrideToken: null,
        overrideEndpoint: null
    };
    warnMessage: string;

    @Input()
    set PrivMask(pi) {
        this.privMask = pi;
    }

    @Input()
    set CatId(pi) {
        this.catId = pi;
    }
    @Input()
    set Comp(pi) {
        this.isComp = pi;
    }
    @Input()
    set Consolidated(pi){
        this.isConsolidated = pi;
    }
    @Input()
    set OverrideEndpoint(endpoint: any) {
        if (endpoint) {
            this.overrideObject.overrideEndpoint = endpoint;
        }
    }
    @Input() frmDPVVerf: boolean;
    @Input() IsFrmInfo : boolean;
    @Input() IsNotesDisplay : boolean;
    @Input() PrescReFillId : any;
    @Input() PrescriptionId : any;
    @Input() RxFG : any;
    @Input() DrugChangeOnErx : boolean;
    @Input() RxInfo : any;
    searchParams: searchTrackPatientCriteria;
    notesLogInfo: any;
    @Output()
    OverrideLoginDet = new EventEmitter<any>();
    editorConfig: AngularEditorConfig = {
        editable: true,
          spellcheck: true,
          height: '50px',
          minHeight: '0',
          maxHeight: 'auto',
          width: 'auto',
          minWidth: '0',
          translate: 'yes',
          enableToolbar: true,
          showToolbar: true,
          placeholder: 'Notes',
          defaultParagraphSeparator: '',
          defaultFontName: '',
          defaultFontSize: '',
          toolbarHiddenButtons: [
            ['undo',
            'redo',
            'underline',
            'subscript',
            'superscript',
            'justifyLeft',
            'justifyCenter',
            'justifyRight',
            'justifyFull',
            'indent',
            'outdent',
            'insertUnorderedList',
            'insertOrderedList',
            'heading',
            'fontName'],
            [
                'fontSize',
                'textColor',
                'backgroundColor',
                'customClasses',
                'link',
                'unlink',
                'insertImage',
                'insertVideo',
                'insertHorizontalRule',
                'removeFormat',
                'toggleEditorMode'
              ]
          ]
    };
    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event.which === 27 && this.modal && this._modalSvc.hasOpenModals()) { // esc
            this.modal.close();
            this.modal = null;
            event.preventDefault();
        }
    }
    IsSubmitted = false;
    commonlabels: any;
    constructor(
        private _fb: FormBuilder,
        private _userService: UserService,
        private _modalSvc: NgbModal,
        public activeModal: NgbActiveModal,
        private _commonServ: CommonService,
        private _privMaskGuard: PrivMaskGuard,
        private _alertSvc: AlertService,
        private _rxSvc: RxService,
        private _notesLogSvc: NotesLogService,
        private _drugServ: DrugService
    ) {
        this.createNoteForm();
        this.OverrideLogFG = this._fb.group({
            PharmUsername: null,
            PharmPassword: null,
            TechUsername: null,
            TechPassword: null,
            subDomain: "localhost:90",
            CatName: null,
            Priv: null,
            PharmacyId: this._userService.getToken("UniquePharmacyId"),
        });
        this.OverRideCmntFG = this._fb.group({
            comment: null
        });
        this._commonServ.resetOverrideObj();
    }
    createNoteForm() {
        this.notesLogFG = new FormGroup({
            Id: new FormControl(),
            TenantId : new FormControl(),
            AppUserId:  new FormControl(),
            xNote:   new FormControl(),
            NoteCatId:  new FormControl(),
            IsShownAsPopup: new FormControl(),
            IsActive:   new FormControl(),
            IsDeleted:  new FormControl(),
            CreateDtTm:  new FormControl(),
            ModifiedDtTm:  new FormControl(),
            Name:  new FormControl(),
            PopupScreenCodes:  new FormControl(),
            IsAddItem: new FormControl(),
        });
        this.setDefaultValues();
    }
    setDefaultValues() {
        this.notesLogFG.controls['IsActive'].patchValue(true);
        this.notesLogFG.controls['AppUserId'].patchValue(2);
        this.notesLogFG.controls['CreateDtTm'].patchValue(moment(Date.now()).format("MM/DD/YYYY"));
        this.notesLogFG.controls['ModifiedDtTm'].patchValue( moment(Date.now()).local().format(
            "MM/DD/YYYY hh:mm:ss a"
        ));
        this.notesLogFG.controls['IsDeleted'].patchValue(false);
        this.notesLogFG.controls['IsShownAsPopup'].patchValue(false);
    }
    ngOnInit() {
        this.commonlabels = new CommonHeaders();
        this.getNotesSubacatList();
        const PrivValue = this._privMaskGuard.canActivate(AccCategoryE.Settings, SettingsE.ManageOverrideActions);
        if (!this.isComp && PrivValue && !this.IsNotesDisplay) {
        this.modal = this._modalSvc.open(this.OverRide, { centered: true, backdrop: false , keyboard: false });
        }
        if (!PrivValue) {
            this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
            if (this.isComp) {
                this.activeModal.close(false);
            }
        }       
    }
    
    getNotesSubacatList(catObj?: any) {
        this.searchParams = {
            SearchText: null,
            SearchBy: null,
            OrderByColumn: null,
            SortDirection: null,
            PageNumber: 1,
            PageSize: 30,
            PrescrefillId: null
        };
        this._notesLogSvc
        .getNotesLog(
            "RxAnnotation",
          this.PrescReFillId ? this.PrescReFillId : 0,
          "1973-01-01",
          moment(Date.now()).format(MsgConfig.DATE_FORMAT_WITH_EIPHEN),
            this.searchParams
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((resp: any) => {
            if(resp)
            {
                this.notesLogInfo = resp;
                // eRx related subcategories we have to hide from the dropdown for cancel Rx's only will show it in the Edit Mode
                this.notesLogInfo.NoteCat = resp?.NoteCat?.filter((item: any) => (item?.CategoryName.toUpperCase() !== 'ERX'));
                let manualSubCat:any;
                if(this.RxFG?.value?.['rxType'] !== 'nr' && this.notesLogFG.value.xNote && (this.notesLogFG.value.NoteCatId)) {
                    this.addNotestoRx();
                } else {
                    if(catObj) {
                        if(catObj.Name) {
                            manualSubCat = resp?.NoteCat?.find((item: any) => (catObj.Name.toUpperCase() === item?.Name?.toUpperCase()));
                        }
                        this.notesLogFG .patchValue({
                            NoteCatId:  manualSubCat ? manualSubCat.Id : 0,
                        });  
                        if(this.RxFG.value['RxNotes']?.length)
                            {
                              const rxNotes = this.RxFG.value['RxNotes'];
                              rxNotes.unshift(this.notesLogFG.value);
                              this.RxFG.controls['RxNotes'].patchValue(rxNotes);
                            }
                            else 
                                 this.RxFG.controls['RxNotes'].patchValue([this.notesLogFG.value]);
                            this.closeCommentModal();
                     }
                  
                }
                
            }
        });
    }
    setOverrideLog() {
        this.OverrideLogFG.controls["CatName"].patchValue(this.catId);
        this.OverrideLogFG.controls["Priv"].patchValue(this.privMask);
        if(!this.OverrideLogFG.value["PharmUsername"] || !this.OverrideLogFG.value["PharmPassword"]) {
            this._alertSvc.error(MsgConfig.MANDATORY_FIELDS);
        } else {
            if(!this.IsFrmInfo){
                this._userService
            .checkPrivCheck(this.OverrideLogFG.value)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(async resp => {
                if (resp) {
                    if (Number(Object.keys(resp)[0]) === 0 && !resp[0].ErrorMessage) {
                        if (!this.isComp && this.modal) {
                            this.modal.close();
                        }
                        this.auditObj.OverrideResult = false;
                        this.auditObj.overrideUserId = null;
                        this.auditObj.overrrideUserName = this.OverrideLogFG.value["PharmUsername"];
                        await this.overridelogAdd();
                        this.errorMs = "UserName and Password is invalid";
                        this._alertSvc.error(this.errorMs);
                        // this.modal = this._modalSvc.open(this.OverRidew, {
                        //     centered: true, backdrop: false, keyboard: false
                        // });
                    } else if (Number(Object.keys(resp)[0]) > 0 && Object.values(resp)[0]) {
                        this.auditObj.overrideUserId = Number(Object.keys(resp)[0]);
                        this.auditObj.OverrideResult = true;
                        this.auditObj.overrrideUserName = this.OverrideLogFG.value["PharmUsername"];
                        if (!this.isComp && this.modal) {
                            this.modal.close();
                            this.modal = this._modalSvc.open(this.Comments, {
                                centered: true, backdrop: false, keyboard: false
                            });
                        } else {
                            if(this.IsNotesDisplay && this.notesLogFG.value.xNote && (this.notesLogFG.value.NoteCatId || this.notesLogFG.value.Name)) {
                                this.addNewNotes();
                             } else {
                                if(this.IsNotesDisplay) {
                                    this.closeCommentModal();
                                } else 
                                this.CommentsOpen = true;
                             }                            
                        }
                        this.overrideObject.overrideToken = Object.values(resp)[0].access_token;
                        // this.OverrideLoginDet.emit(true);
                    } else if (!Object.values(resp)[0]) {
                        this.auditObj.overrideUserId = Number(Object.keys(resp)[0]);
                        this.auditObj.overrrideUserName = this.OverrideLogFG.value["PharmUsername"];
                        this.auditObj.OverrideResult = false;
                        // this.auditObj.overrrideUserName = this.OverrideLogFG.value["PharmUsername"];
                        this.overridelogAdd();
                        // if (!this.isComp) {
                        //     this.modal.close();
                        // } else {
                        //     this.activeModal.dismiss();
                        // }
                        this.errorMs = "User does not have privilege to perform this action.";
                        this._alertSvc.error(this.errorMs);
                        if(this.errorMs){
                            this.OverrideLogFG.controls["PharmUsername"].patchValue(null);
                            this.OverrideLogFG.controls["PharmPassword"].patchValue(null);
                        }
                        // this.modal = this._modalSvc.open(this.OverRidew, {
                        //     centered: true, backdrop: false, keyboard: false, windowClass: "large--content"
                        // });
                    }
                } else {
                    this.auditObj.overrideUserId = null;
                    this.auditObj.OverrideResult = false;
                    this.auditObj.overrrideUserName = this.OverrideLogFG.value["PharmUsername"];
                    this.overridelogAdd();
                    if (!this.isComp && this.modal) {
                        this.modal.close();
                    }
                    this.errorMs = "Override unsuccessful.";
                    this._alertSvc.error(this.errorMs);
                    // this.modal = this._modalSvc.open(this.OverRidew, {
                    //     centered: true, backdrop: false, keyboard: false, windowClass: "large--content"
                    // });
                }
            });
            } else{
                if(this.modal){
                    this.modal.close();
                }
                this.verifyAuthPassword();
            }
        }
        
    }
    addNewNotes() {
        const   notesCatList: any = this._commonServ.notesCatList$["source"]["value"];
        const mainCat = notesCatList.find((val: any) => { if(val.CategoryName.toUpperCase() === NotesMainCat.RxAnnonations.toUpperCase()) return val})
        if(this.RxFG?.value?.['rxType'] === 'nr' && this.notesLogFG.value.NoteCatId) {
            if(this.RxFG.value['RxNotes']?.length)
                {
                  const rxNotes = this.RxFG.value['RxNotes'];
                  rxNotes.unshift(this.notesLogFG.value);
                  this.RxFG.controls['RxNotes'].patchValue(rxNotes);
                }
                else 
                     this.RxFG.controls['RxNotes'].patchValue([this.notesLogFG.value]);
                this.closeCommentModal();
        }
        else if(this.RxFG?.value?.['rxType'] === 'nr' && !this.notesLogFG.value.NoteCatId) {
            this._drugServ.addoRUpdateNoteCategory(
                {
                    Name: this.notesLogFG.value.Name,
                    NoteCategoryId: mainCat?.CategoryId,
                    IsActive: true,
                    CreatedDtTm: null,
                    Id:0
                },
                "SAVE"
            ).pipe(takeUntil(this.unsubscribe$)).subscribe((resp: number) => {
                if (resp) {
                    this.getNotesSubacatList({
                        Name: this.notesLogFG.value.Name
                    });
                }
            });
        } else {
            let NoteCat: any = [];
            if(!this.notesLogFG.value.NoteCatId && this.RxFG?.value?.['rxType'] !== 'nr')
            {
                           NoteCat =  [
                    {
                        Id: 0,
                        TenantId: null,
                        Name: this.notesLogFG.value.Name,
                        Remarks: null,
                        IsActive: true,
                        IsAddItem: true,
                        CreatedDtTm: moment(Date.now()).format("MM/DD/YYYY"),
                        ModifiedDtTm: moment(Date.now()).local().format("MM/DD/YYYY hh:mm:ss a"),
                        NoteCategoryId: mainCat?.CategoryId
                    }
                ]
                this.addNotestoRx(NoteCat);
            } else {
                this.addNotestoRx();
            }
        } 
    }
    addNotestoRx(NoteCat?) {
        this._notesLogSvc
        .addNotesLog("RxAnnotation",this.PrescriptionId, 
            {Note: [this.notesLogFG.value] , NoteCat : !(this.notesLogFG.value.NoteCatId ) ? NoteCat : null})
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if(resp)
            this.closeCommentModal();
        }); 
    }
    
    async verifyAuthPassword() {
        this.warnMessage= "";
        if (this.OverrideLogFG.valid) {
            const resp = await this._rxSvc.checkTechicianPassword(this.OverrideLogFG.value["PharmPassword"],this.OverrideLogFG.value["PharmUsername"]).toPromise();
            if (resp && resp.Status === 1) {
                if (this.modal) {
                    this.modal.close();
                    this.modal = null;
                }
                this.auditObj.overrideUserId = _.toNumber(this._userService.getToken("UserId"));
                this.auditObj.OverrideResult = true;
                this.auditObj.overrrideUserName = this.OverrideLogFG.value["PharmUsername"];
                if(!this.IsNotesDisplay){
                    this.CommentsOpen = true;
                } else {
                    if(this.notesLogFG.value.xNote && (this.notesLogFG.value.NoteCatId || this.notesLogFG.value.Name)) {
                       this.addNewNotes();
                    } else {
                        this.closeCommentModal();
                    }
                }
            } else {
                if (resp && resp.Status ===  2) {
                    this._alertSvc.error(resp.IsAdminUser ?
                    "Admin user account is locked due to multiple attempts with wrong password. Please contact support team to unlock your account."  :
                    "Pharmacist/Technician user account is locked due to multiple attempts with wrong password. Please contact your Admin to unlock your account.");
                } else {
                    this._alertSvc.error("Invalid Credentials.");
                }
                this.OverrideLogFG.reset();
                if(document.getElementById("Username"))
                    document.getElementById("Username").focus();
           }
        }
    }
    closeModal() {
        if (!this.isComp && this.modal) {
        this.modal.close();
        this.OverrideLoginDet.emit(false);
        } else {
            this.activeModal.close(false);
        }
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    closeModal2() {
        if (this.modal) {
            this.modal.close();
        }
        if (!this.isComp) {
        this.modal = this._modalSvc.open(this.OverRide, { centered: true, backdrop: false , keyboard:false});
        }
    }

    async closeCommentModal() {
        await this.overridelogAdd();
        if (!this.isComp && this.modal) {
            this.modal.close();
            this._commonServ.setOverrideObj(this.overrideObject);
            this.OverrideLoginDet.emit(true);
        } else {
          //  this.modal.close();
            this.CommentsOpen = false;
            this.activeModal.close(true);
        }
    }

    async overridelogAdd() {
        this.auditObj.entity = this.catId;
        this.auditObj.action = this.privMask;
        this.auditObj.Comments = this.IsNotesDisplay ? this.notesLogFG.value["xNote"] : this.OverRideCmntFG.value["comment"];
        this.auditObj.created = moment().format("YYYY/MM/DD");
        this.auditObj.isOverride = true;
        this.auditObj.userid = _.toNumber(this._userService.getToken("UserId"));
        await this._commonServ.sendAuditChanges(this.auditObj).toPromise();
    }
}
