<ng-template #SingleNotesPopup let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="continueSavingRx()">
        <h4 header>
            {{HeaderText}}
        </h4>
        <span body>
            <div class="eprx-font-16">
                {{BodyText}}
            </div>
            <div class="eprx-row eprx-form-group mt-4"> <!---eprx-form-group -> to avoid  curret margin bottom for form-group class-->
                <div class="eprx-col-12  pl-0" >
                    <div class="eprx-card">
                       <div class="eprx-row">
                        <div class="eprx-card-header">
                            {{commonlabels?.Notes}}
                        </div>
                       </div>
                       <div class="eprx-row">
                        <div  class="eprx-card-label--data eprx-col-6 px-0">
                            <eprx-input [RxSelectId]="'MainCategory'"  [LabelText]="'Category'" [HasControl]="false" [InputValue]="MainCategory" [IsDisabled]="true" >
                            </eprx-input>
                       </div>
                       <div  class="eprx-card-label--data eprx-col-6  pr-0">
                        <eprx-select [RxSelectId]="'notesLogCategry'" [AddItem]="true" [SelectOnTab]="true"
                            [LabelText]="'Subcategory'" [PlaceHolder]="'Subcategory'" [IsTitle]="true" 
                            [ControlName]="'NoteCatId'" [FormGroupName]="notesLogFG" [List]="notesLogInfo?.NoteCat" 
                            [BindLabel]="'Name'"  [secondCntrlN]="'Name'" [bindScnCntrlV]="'Name'"  [BindValue]="'Id'" [addItemMaxLength]="15" [AutoFocus]="true"
                            [LabelForId]="'Name'" [InformationTool]="'Add a subcategory manually if it is not available in the list.'">
                        </eprx-select>
                       </div>
                        </div>      
                        <div class="eprx-row">  
                            <div class="eprx-card-label--data eprx-col-12 px-0" [formGroup]="notesLogFG">
                                <angular-editor formControlName="xNote" [config]="editorConfig"></angular-editor>
                              </div>
                        </div>
                      
                    </div>
                </div>
            </div>
        </span>
        <button footer type="submit" class="hotkey_success"  ngbAutofocus  appShortcutKey  [AltKey]="'o'"
            (keydown.enter)="continueSavingRx()" (click)="continueSavingRx()">
            <span>O</span> Ok
        </button>
    </app-custom-modal>
</ng-template>