import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { constant } from "../app.constants";
import { AuditTrail, DailyLog } from "../models";
import {
    ControlRx,
    FiledRx,
    RxByPharmacist,
    RxByPrescriber,
    SummaryReports,
    UnbilledRx
} from "./../models/reports.model";

const reqOptns = {
    headers: new HttpHeaders().set("isLoadNotReq", "true")
};
@Injectable({
    providedIn: "root"
})
export class ReportsService {
    constructor(private _http: HttpClient) {}

    sendDailyLogFilters(dailyLogInfo: DailyLog): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Reports_DailyLog, dailyLogInfo, {
            headers: headers,
            responseType: "blob"
        });
    }
    getInventoryTransactionAuditReport(AuditTrailInfo: AuditTrail): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Reports_TransactionAudit, AuditTrailInfo, {
            headers: headers,
            responseType: "blob"
        });
    }

    getDailyLogDropDowns() {
        return this._http.get(constant.GET_Reports_DailyLog_DropDown);
    }

    sendControlRxFilters(controlRxInfo: ControlRx): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Reports_ControlRx, controlRxInfo, {
            headers: headers,
            responseType: "blob"
        });
    }

    getFiledRxDropDowns() {
        return this._http.get(constant.GET_Reports_FiledRx_DropDown);
    }

    sendFiledRxFilters(filedRxInfo: FiledRx): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Reports_FiledRx, filedRxInfo, {
            headers: headers,
            responseType: "blob"
        });
    }
    viewDataSet(data): Observable<any> {
        return this._http.post(constant.POST_Drug_Report_ViewDataSet, data);
    }

    viewDataSetInventory(data): Observable<any> {
        return this._http.post(constant.POST_Inventory_Report_ViewDataSet, data);
    }

    viewDataSetInventoryReconciliation(data):Observable<any>{
        return this._http.post(constant.POST_Inventory_Reconciliation_Report_ViewDataSet, data);
    }

    saveExplanationInventoryReconciliation(data):Observable<any>{
        return this._http.post(constant.POST_Inventory_Reconciliation_Report_Explanation, data);
    }

    sendSummaryFilters(summaryInfo: SummaryReports): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Reports_Summary, summaryInfo, {
            headers: headers,
            responseType: "blob"
        });
    }

    getTopPerformersDropDownData(){
        return this._http.get(constant.GET_TOPPERFOMERS_DATA);
    }

    sendTopPerfomersData(params: any): Observable<any> {
        return this._http.post<any>(constant.POST_TOPPERFORMERS_DATA, params);
    }

    sendAddressLabelData(params: any) {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_TOPPERFRMS_ADDRESSLABEL_DATA,params,{
            headers: headers,
            responseType: "blob"
        })
    }

    getGraphsData(summaryInfo: any): Observable<any> {
        return this._http.post(constant.POST_Reports_Summary_Graph, summaryInfo, );
    }

    getTopPerfrmsReport(params: any) {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_TOPPERFRMSREPORTDATA,params,{
            headers: headers,
            responseType: "blob"
        })
    }

    sendUnbilledRxFilters(unbilledInfo: UnbilledRx): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Reports_UnBilledRx, unbilledInfo, {
            headers: headers,
            responseType: "blob"
        });
    }

    sendRxByPharmacistFilters(rxbyPharm: RxByPharmacist): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Reports_RxByPharmacist, rxbyPharm, {
            headers: headers,
            responseType: "blob"
        });
    }

    sendRxByPrescFilters(rxbyPresc: RxByPrescriber): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Reports_RxByPrescriber, rxbyPresc, {
            headers: headers,
            responseType: "blob"
        });
    }

    getRxByPharmDropDowns() {
        return this._http.get(constant.GET_Reports_RxByPharmacist_DropDown);
    }

    addTaskToScheduler(data: any) {
        return this._http.post(constant.POST_ADD_TASK_FOR_SCHEDULER,data);
    }

    deleteTask(data: any): Observable<any> {
        return this._http.put<any>(constant.PUT_SCHEDULE_TASK,data)
    }

    getTaskExecHis(params: any): Observable<any> {
        return this._http.post<any>(constant.POST_GET_TASKEXCHIST,params);
    }

    taskNameExists(name: any): Observable<any> {
        return this._http.post<any>(constant.POST_TASKNAME_ALREADYEXISTS,name,reqOptns);
    }

    getLatestTaskInfo(id: any): Observable<any> {
        return this._http.get<any>(constant.GET_LATEST_TASKINFO.replace("{taskScheduleId}",""+id));
    }

    runTaskNow(data: any): Observable<any> {
        return this._http.post<any>(constant.POST_RUNTASK_NOW,data)
    }

    getTaskList(data) : Observable<any>{
        return this._http.post<any>(constant.POST_SCHEDULE_TASKLIST,data);
    }

    getRxByPrescDropDowns() {
        return this._http.get(constant.GET_Reports_RxByPrescriber_DropDown);
    }
    getDrugCategoriesBySelection() {
        return this._http.get(constant.GEt_Reports_ByDrugCat);
    }
    getDrugCatConceptNames(data) {
        return this._http.post(constant.GEt_Reports_DrugCatConceptNames,data);
    }
    patientAdherenceReportData(params) {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.GET_Drug_Report_PatientAdherenceReportData, params, {
            headers: headers,
            responseType: "blob"
        });
    }
    addMedicationDruCat(data) {
            return this._http.post<any>(
                constant.POST_Reports_AddMedicationDrugCategory.replace(
                    "{categoryName}",
                    "" + data.categoryName
                ),
                data
            );
    }
    addDrugConcept(data) {
            return this._http.post<any>(
                constant.POST_Reports_AddDrugConcept,
                data
            );
    }
    deleteMedicationDruCat(categoryId) {
            return this._http.delete<any>(
                constant.DELETE_Report_DeleteMedAdhereCategory.replace(
                    "{categoryId}",
                    "" + categoryId
                )
            );
    }
    deleteConceptName(id) {
        return this._http.delete<any>(
            constant.DELETE_Report_DeleteConceptName.replace(
                "{id}",
                "" + id
            )
        );
}
    getPatientsBySelection(params) {
        return this._http.post(constant.POST_Reports_ByPatientSelection, params);
    }
    getRefilDueReport(refilDueParams: any) {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Reports_RefillDue, refilDueParams, {
            headers: headers,
            responseType: "blob"
        });
    }
    getDrugDispenseReport(DispensePars: any, isForEquivalency: boolean) {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        if (!isForEquivalency) {
            return this._http.post(constant.POST_Reports_DrugDispense, DispensePars, {
                headers: headers,
                responseType: "blob"
            });
        } else {
            return this._http.post(constant.POST_Reports_DrugDispenseEquivalency, DispensePars, {
                headers: headers,
                responseType: "blob"
            });
        }
    }
    getRefillExpirationReport(RefillPars: any) {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Reports_RefillExpiration, RefillPars, {
            headers: headers,
            responseType: "blob"
        });
    }
    getProfitRevReport(ProfitPars: any) {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Reports_ProfitRevn, ProfitPars, {
            headers: headers,
            responseType: "blob"
        });
    }

    getERxRequest(ERxPars: any) {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Reports_ErxReport, ERxPars, {
            headers: headers,
            responseType: "blob"
        });
    }

    getHipaaAck(HipaaPars: any) {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Report_HipaaAck, HipaaPars, {
            headers: headers,
            responseType: "blob"
        });
    }

    getRxPickupLog(payload: any) {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Report_PickedUpRx, payload, {
            headers: headers,
            responseType: "blob"
        });
    }

    getRxPickUpDetails(payload) {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Report_PickedUpRxDetail, payload, {
            headers: headers,
            responseType: "blob"
        });
    }
    getPOSCopayCollectedDetails(payload) {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Report_POSCopayCollectedDetail, payload, {
            headers: headers,
            responseType: "blob"
        });
    }


    getLoginAudit(data: any) {
        return this._http.post(constant.GET_Login_Audit, data);
    }

    getLoginAccessData(data: any) {
        return this._http.post(constant.GET_Login_Access_Control, data);
    }

    getCntrlRxSearchFields() {
        return this._http.get(constant.GET_EPCSControlled_RxSearchFields);
    }

    getCntrlChangeData(data: any) {
        return this._http.post(constant.POST_ControlRx_Change, data);
    }

    getUserAuditFeilds() {
        return this._http.get(constant.GET_UserAudit_Fields);
    }

    getUserLogData(data: any) {
        return this._http.post(constant.POST_Get_UserAudit, data);
    }

    getOverrideData(data: any) {
        return this._http.post(constant.POST_Get_Override, data);
    }

    getAnalyseAuditTrail(data: any) {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Reports_Analyse, data, {
            headers: headers,
            responseType: "blob"
        });
    }

    getInventoryReport(data: any) {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Reports_Inventory, data, {
            headers: headers,
            responseType: "blob"
        });
    }

    getPriceListReport() {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Report_PriceList, null, {
            headers: headers,
            responseType: "blob"
        });
    }

    replaceAll(payload: any): Observable<any> {
        return this._http.put<any>(constant.PUT_REPLACEALL , payload);
    }

    getNotPickUpReport(data) {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Report_NotPickUpRx, data, {
            headers: headers,
            responseType: "blob"
        });
    }

    getTransferredRxReport(data) {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Report_TransferredRx, data, {
            headers: headers,
            responseType: "blob"
        });
    }

    getSignatureClaimLog(data) {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Report_SignatureClaimsLog, data, {
            headers: headers,
            responseType: "blob"
        });
    }
    getRxListReportWithNoAttDocs(data) {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Report_RxListWithNoAttDocs, data, {
            headers: headers,
            responseType: "blob"
        });
    }
    getRxListWithAttDocs(data): Observable<any> {
        return this._http.post<any>(constant.POST_RxListWithAttDocs, data);
    }

    getRxListReportWithAttDocs(data) {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Report_RxListWithAttDocs, data, {
            headers: headers,
            responseType: "blob"
        });
    }
    getPrintReportForRejTrans(data): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Print_RejectedTrans, data, {
            headers: headers,
            responseType: "blob"
        });
    }

    getPriceChangeReport(data): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Price_Change_Report, data, {
            headers: headers,
            responseType: "blob"
        });
    }

    getDrugPriceCheckReport(payload): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_DRUG_PRICE_CHECK, payload, {
            headers: headers,
            responseType: "blob"
        });
    }

    getZipcodes(id?): Observable<any> {
        return this._http.get(constant.POST_SHARED_ZIPCODES.replace("{stateId}",id));
    }
    getPharmCustomReports(data): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_PHARM_CUSTOM_REPORTS, data, {
            headers: headers,
            responseType: "blob"
        });
    }


    getRphVerifReport(data): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_PHARM_VERIF_REPORT, data, {
            headers: headers,
            responseType: "blob"
        });
    }

    getDphVerifReport(data): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_DPH_VERIF_REPORT, data, {
            headers: headers,
            responseType: "blob"
        });
    }

    getRxFilledSummaryReport(data): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_RX_FILLED_SUM_REPORT, data, {
            headers: headers,
            responseType: "blob"
        });
    }

    getRxsByInsuranceReport(payload): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_RXS_BY_INSURANCES_REPORT, payload, {
            headers: headers,
            responseType: "blob"
        });
    }

    getPartialFillReport(data): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Reports_PartialFill, data, {
            headers: headers,
            responseType: "blob"
        });
    }

    getViewDataSetGrid(data): Observable<any> {
        return this._http.post<any>(constant.POST_Reports_ViewDataSet, data);
    }

    getViewDataSetReport(data): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_Reports_ViewDataSetReport, data, {
            headers: headers,
            responseType: "blob"
        });
    }

    getGsddPriceUpdateReport(data): Observable<any> {
        return this._http.post<any>(constant.POST_Reports_GSDD_PriceUpdate, data);
    }

    IsAsyncReportExist(reportName): Observable<any> {
        return this._http.get(constant.IS_ASYNC_REPORT_EXIST.replace("{ReportName}", reportName));
    }
    getAsyncReport(data): Observable<any> {
        return this._http.post<any>(constant.GENERATE_ASYNC_REPORT, data);
    }
    AsyncReport(data): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.GET_ASYNC_REPORT, data, {
            headers: headers,
            responseType: "blob"
        });
    }
    getAsyncFilters(reportData): Observable<any> {
        return this._http.post(constant.GET_ASYNC_FILTERS, reportData);
    }
    AsyncReportGenerated(addHdrs?:boolean) {
        if (addHdrs) {
            return this._http.get(constant.ASYNC_REPORT_LOGIN, reqOptns);
        } else {
            return this._http.get(constant.ASYNC_REPORT_LOGIN);
        }
    }

    getAllReportLists(payLoad, addHdrs?:boolean) {
        if (addHdrs) {
            let headers = new HttpHeaders();
            headers = headers.set("frRprttWriterBaseUrl", "true");
            headers = headers.set("isLoadNotReq", "true");
            return this._http.post(constant.GET_ALL_REPORTS, payLoad , { headers: headers});
        } else {
            const reqOptns = {
                headers: new HttpHeaders().set("frRprttWriterBaseUrl", "true")
            };
            return this._http.post(constant.GET_ALL_REPORTS, payLoad , reqOptns);
        }
        
    }

    getReportWriterEnitites() {
        const reqOptns = {
            headers: new HttpHeaders().set("frRprttWriterBaseUrl", "true")
      
        };
        return this._http.get(constant.GET_ReportWriter_ElemFilters, reqOptns);
    }

    getAllReportFilters(reportId): Observable<any> {
        const reqOptns = {
            headers: new HttpHeaders().set("frRprttWriterBaseUrl", "true")
      
        };
        return this._http.post(constant.POST_ReportFilters, reportId, reqOptns);
    }

    deletecustomReport(reportId): Observable<any> {
        const reqOptns = {
            headers: new HttpHeaders().set("frRprttWriterBaseUrl", "true")
      
        };
        return this._http.post(constant.DELETE_CUST_REPORT, reportId, reqOptns);
    }

    saveCustomReport(payLoad): Observable<any> {
        const reqOptns = {
            headers: new HttpHeaders().set("frRprttWriterBaseUrl", "true")
      
        };
        return this._http.post(constant.POST_SAVE_CUSTOMREPORT, payLoad, reqOptns);
    }

    getReportDetails(reportID: any): Observable<any> {
        const reqOptns = {
            headers: new HttpHeaders().set("frRprttWriterBaseUrl", "true")
      
        };
        return this._http.get(constant.GET_REPORTDETAILS.replace("{reportId}", reportID), reqOptns);
    }

    updateReportDetails(payLoad): Observable<any> {
        const reqOptns = {
            headers: new HttpHeaders().set("frRprttWriterBaseUrl", "true")
      
        };
        return this._http.post(constant.UPDATE_REPORTDETAILS, payLoad, reqOptns);
    }
    IsAsyncReportExistReprtWriter(reportId, reportName): Observable<any> {
        const reqOptns = {
            headers: new HttpHeaders().set("frRprttWriterBaseUrl", "true")
      
        };
        return this._http.get(constant.IS_ASYNC_REPORT_EXIST_RW.replace("{ReportId}", reportId).replace("{ReportName}", reportName), reqOptns);
    }
    getAsyncReportReprtWriter(data): Observable<any> {
        const reqOptns = {
            headers: new HttpHeaders().set("frRprttWriterBaseUrl", "true")
      
        };
        return this._http.post<any>(constant.GENERATE_ASYNC_REPORT_RW, data, reqOptns);
    }
    AsyncReportReportWriter(data): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        headers = headers.set("frRprttWriterBaseUrl", "true");
        return this._http.post(constant.GET_ASYNC_REPORT_RW, data, {
            headers: headers,
            responseType: "blob"
        });
    }
    getAsyncFiltersReportWriter(reportData): Observable<any> {
        const reqOptns = {
            headers: new HttpHeaders().set("frRprttWriterBaseUrl", "true")
        };
        return this._http.post(constant.GET_ASYNC_FILTERS_RW, reportData, reqOptns);
    }
    viewDataSetInventoryReconciliationReportWriter(data):Observable<any>{
        const reqOptns = {
            headers: new HttpHeaders().set("frRprttWriterBaseUrl", "true")
        };
        return this._http.post(constant.POST_Inventory_Reconciliation_Report_ViewDataSet_RW, data, reqOptns);
    }
    isDuplicateReportNameExists(payLoad:any) {
        const reqOptns = {
            headers: new HttpHeaders().set("frRprttWriterBaseUrl", "true")
        };
        return this._http.post(constant.IS_DUPLICATE_REPORTNAME_RW, payLoad, reqOptns);
    }
    getRemittanceViewData(data): Observable<any> {
        const reqOptns = {
            headers: new HttpHeaders().set("frRprttWriterBaseUrl", "true")
        };
        return this._http.post<any>(constant.POST_ViewData_Remittance, data, reqOptns);
    }
}
