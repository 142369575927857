import { AuditLogUtils } from "./../../../utils/audit-log.util";
import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
    ChangeDetectorRef,
    Input,
    HostListener,
    AfterViewInit,
    OnChanges
} from "@angular/core";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { formatDate, DatePipe } from "@angular/common";
import {
    AlertService,
    RphVerificationService,
    CommonService,
    EditDrugService,
    PatientService,
    EditPrescriberService,
    ErxService,
    DrugService,
    RxService,
    ControlFileService,
    InventoryService
} from "src/app/services";
import {
    EPatient,
    EDrug,
    SystemData,
    Phone,
    Address,
    Person,
    Email,
    PrescSpeciality,
    PrescAdds,
    PrescriberSalesPerson,
    PrescriberSplProg,
    Drug,
    City,
    State,
    InsuranceData,
    AccCategoryE,
    RxE,
    EditRxE,
    RxEntryE,
    EPrescriber,
    PatientFileE,
    PrescriberFileE,
    DrugFileE,
    PaginationModel,
    PresClinicalAddress
} from "src/app/models";
import { PatientInfoUtil } from "src/app/utils/patient-info.util";
import { FormGroup, FormBuilder, FormControl, FormArray, Form } from "@angular/forms";
import { RxUtils, PatchRXFGUtil, PrescriberUtil, DrugUtils, CommonUtil } from "src/app/utils";
import { MsgConfig } from "../../../app.messages";
import { Observable, Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, map, take, takeUntil } from "rxjs/operators";
import * as _ from "lodash";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { CollectionView } from "@grapecity/wijmo";
import { RegEx } from "../../../app.regex";
import { PrivMaskGuard } from "src/app/guards";
import * as moment from "moment";
import { DomSanitizer } from "@angular/platform-browser";
import { PatientComponent } from "../../patient";
import { NRxUtils } from "src/app/utils/nRx.util";
import { Router } from "@angular/router";
import { PrescriberComponent } from "../../prescriber";
import { DrugComponent } from "../../drug/drug.component";
import { DrugBucketUtil } from "src/app/utils/drug-bucket.util";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { WijimoUtil } from "src/app/utils";
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { RxStore } from "src/app/store";
import { InprogressService } from "src/app/services/inprogress.service";
import { InventoryDetailsComponent } from "../../shared/inventory-details/inventory-details.component";
import { EquivalentDrugsComponent, OverrideLogComponent } from "../../shared";
import { CommonPrintService } from "src/app/services/common-print.service";
import { CommonWarnorconfirmPopupComponent } from "../../shared/common-warnorconfirm-popup/common-warnorconfirm-popup.component";
import { FormatsUtil } from "src/app/utils/formats.util";

@Component({
    selector: "app-erx-to-newrx-check",
    templateUrl: "./erx-to-newrx-check.component.html"
})
export class ErxToNewrxCheckComponent implements OnInit {
    prescriberVlidationCompleted: any;
    PatientValidationCompleted: any;
    modalRef: any;
    bucketId: any;
    rxOrdDt: string;
    EfctDt: string;
    hasPayRecords: boolean;
    overrideFor: any;
    overrideEndpoint: any;
    patientPrivs:  any;
    prescPrivs: any;
    drugPrivs: any;
    alreadyPrivCheck: any;
    PrescRefInfo: any[]=[];
    prescPhoneNumCheck: any;
    isDrugFormBarcode: boolean;
    dawYaddingDrug = false;
    isDawY: boolean = false;
    modelRef1: any;
    selectedDrugInfo: any;
    revAllowDays: any;
    isFrmPatientChange: boolean;
    modelRefChange: any;
    isPatientMatched = true;
    isPrescriberMatched = true;
    isPrescrNameNotMatched: boolean;
    prescAddressDetails: any;
    isSPIMatched: any;
    rxFG: FormGroup;
    isPrescriberDEANotMatched: boolean;
    @HostListener('window:beforeunload')
    UpdateErxFlagStatus() {
        this.closeModal(true);
    }
    @Input()
    set ErxData(data: any) {
        this.erxData = data;
        if (data.Version) {
            this.eRxVersion = data.Version;
        }
    }

    @Input()
    set ErxDetailsInfo(data: any) {
        if (data) {
            this.detailsInfo = data;
        }
    }

    @Input()
    set ErxProcessIds(data: any) {
        if (data) {
            this.processIdsObj = data;
        }
    }

    @Input()  IsFromBefitCheck:any;
    
    @Input()  FromIntakeQueue:any;


    @Input()
    set EMessageType(emt: any) {
        this.eMessageType = emt["Message Type"];
        this.eRxWJDetails = emt;
        // if (this.eMessageType["Message Type"] === "REFRES") {
        //     this.index = 2;
        // }
    }
    @Output()
    IsPopUpClosed = new EventEmitter<null>();

    @Output()
    IsPopUpClosed1 = new EventEmitter<boolean>();
    @Output()
    RefreshGrid = new EventEmitter<null>();


    @ViewChild("PatPreDrugCheck", { static: true })
    PatPreDrugCheck: any;

    @ViewChild("PatNotMatch", { static: true })
    PatNotMatch: any;

    @ViewChild("PreNotMatch", { static: true })
    PreNotMatch: any;

    @ViewChild("DrugNotPrefered", { static: true })
    DrugNotPrefered: any;

    @ViewChild("DrugNdcNotMatch", { static: true })
    DrugNdcNotMatch: any;

    @ViewChild("SelectDefaultSig", { static: true })
    SelectDefaultSig: any;

    @ViewChild("IsPrn", { static: true })
    IsPrn: any;

    @ViewChild("DrugQuantity", { static: true })
    DrugQuantity: any;

    @ViewChild("equiDrugs")
    equiDrugs: any;

    @ViewChild("MaxRefillsForDrugClass", { static: true })
    MaxRefillsForDrugClass: any;

    @ViewChild("CancelReqTemp", { static: true })
    CancelReqTemp: any;

    @ViewChild("SelectCancelRx", { static: true })
    SelectCancelRx: any;

    @ViewChild("AcceptRejectRx", { static: true })
    AcceptRejectRx: any;

    @ViewChild("RevDelRx", { static: true })
    RevDelRx: any;

    @ViewChild("ReverseCancelRx", { static: true })
    ReverseCancelRx: any;

    @ViewChild("DeleteCancelRx", { static: true })
    DeleteCancelRx: any;

    @ViewChild("PatPhoneAddMatch", { static: true })
    PatPhoneAddMatch: any;

    @ViewChild("PrescribDeaMatch", { static: true })
    PrescribDeaMatch: any;

    @ViewChild("SPInotFound", { static: true })
    SPInotFound: any;

    @ViewChild("expiredToReverse", { static: true })
    expiredToReverse: any;

    @ViewChild("compList")
    compList: any;

    @ViewChild("selcDrugNotEqui", { static: true })
    selcDrugNotEqui: any;

    erxId: any;
    erxData: any;
    fileId: any;
    inputValue = "";
    PhnRegex: any;
    eqDrugsSize: number;
    prefDrugSize: number;
    prefDrugList: any;
    hasPrefDrug: boolean;
    sigCodeData: string;
    SigFG: FormGroup;
    authRefills: any = [];
    processIdsObj: any;
    isPolcRequired: boolean;
    selectedDrugQuantity: any;
    defaultDrugEditQty: FormGroup;
    accessPrivCate: any;
    rxPrivs: any;
    rxEntryPrivs: any;
    editRxPrivs: any;
    privType: any;
    categoryId: any;
    openOverride: boolean;
    routeRxInfo: any;
    hasRxHistory = true;
    controlRefillSettings: any;
    refillSettingLimit: any;
    eRxWJDetails: any;
    cancelPatient: any;
    cancelRxInfo = [];
    cancelOrgRxInfo: any;
    accRejPopUp: any;
    accRejPopUpType: string;
    rxsToProcess: any;
    currentProcessingRx = [];
    reverseModal: any;
    deleteModal: any;
    reverseRxs = [];
    deleteRxs = [];
    reverseTrasmitArray = [];
    reverseListModal: any;
    sortedOrgInfo = [];
    cancelPharmNotes: any;
    cancelReason: any;
    eqqWijmo: wjcGrid.FlexGrid;
    isDrugInactive: boolean;
    inactivePresc: boolean;
    inactivePat: boolean;
    responseState: any;
    isForTransmitReversalOnly = false;
    eRxVersion: any;
    enableCancelRefillNo: any;
    enableDeselect: any;
    differenceDays: number;
    totalCompoundDrugs: any;
    compSelOption: any;
    compSelctionList: any;
    selectedCompoundDrug: any;
    compDrugSearchInp= "";
    compAddedNew: boolean;
    compDrugPageNumber=1;
    compTotalCount: any;
    CompDrugFG: FormGroup;
    report: any;
    pdfContent: any;
    defaultSimilarDrug = true;
    addedCompDrug: any;
    msg = "Added Drug";
    prescribedEqDrugs: EDrug[]=[];
    tempEqDrug: any;
    drugSuggestDet: any;
    modelRef: any;
    modalRef2: any;
    actModal2: string;
    index = 0;
    rxPatientModel: EPatient;
    prescriberInfo: EPrescriber;
    equivalentList: any = null;
    drugInfo: EDrug;
    detailsInfo: any;
    patientInfoFG: FormGroup;
    systemData: SystemData;
    prescriberFG: FormGroup;
    drugEditFG: FormGroup;
    defVal: any;
    PatientCitySelected: City;
    PatientStateSelected: State;
    PrescCitySelected: City;
    PrescStateSelected: State;
    DrugId: any;
    PatientId: number;
    PrescriberId: number;
    PrescZip: any;
    patZip: any;
    patFormSubmit = false;
    preFormSubmit = false;
    drugFormSubmit = false;
    insuranceData: any;
    patientInputValue = "";
    prescriberInputValue = "";
    drugInputValue = "";
    addPatient = false;
    addPrescriber = false;
    addDrug = false;
    equivalentDrugWJ: CollectionView;
    eMessageType: string;
    isInputFocused = false;
    equivalentDrugWJSource: CollectionView;
    wjHeaders: { hName: string; isVisible: boolean; width: number; }[];
    skipPopUp: any;
    eRxDurgId: any
    prefDrugSetting: any;
    paidPayRecords = [];
    expireDate = [];
    isBilledOffset: any;
    isUnbilledOffset: any;
    isDPVOffset: any;
    currentRx: any;
    unsubscribe$: Subject<void> = new Subject();
    paginationModel: PaginationModel = new PaginationModel();
    pageNumber = this.paginationModel.pageNumber;
    pageSize = this.paginationModel.pageSize;
    subscriptions = new Subscription();

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event.which === 27 && (this.modalRef2)) { // esc
            if (this.modalRef2) {
                this.modalRef2.close();
                this.modalRef2 = null;
                if (!this.modelRef) {
                    this.closeModal();
                }
            // } else if (this.modelRef) {
            //     this.closeModal(true);
            } else if (this.reverseListModal) {
                this.closeReverseListModal();
            }
            event.preventDefault();
        }
    }


    constructor(
        config: NgbModalConfig,
        private _modalService: NgbModal,
        private _cdr: ChangeDetectorRef,
        private _alertService: AlertService,
        private _rphService: RphVerificationService,
        public _commonServ: CommonService,
        private _fb: FormBuilder,
        private _erxService: ErxService,
        private _rxUtils: RxUtils,
        private _privMaskGuard: PrivMaskGuard,
        private _auditUtils: AuditLogUtils,
        private _commonSvc: CommonService,
        private _drugService: DrugService,
        private _rxService: RxService,
        private _ctrlFileSvc: ControlFileService,
        private _sanitizer: DomSanitizer,
        private _nrxUtils: NRxUtils,
        private _router: Router,
        private _patchRx: PatchRXFGUtil,
        private _patUtils: PatientInfoUtil,
        private _prescriberUtil: PrescriberUtil,
        private _drugUtil: DrugUtils,
        private _drugBuckUtil: DrugBucketUtil,
        private _wijimoUtils: WijimoUtil,
        private currencyPipe: CurrencyPipe,
        private decimalPipe: DecimalPipe,
        private _rxStore: RxStore,
        private _inprService: InprogressService,
        private _inventoryService: InventoryService,
        private _commonUtil: CommonUtil,
        private _cmmnPrntSrv: CommonPrintService,
        public _formatsUtil: FormatsUtil,
        private _prescService: EditPrescriberService
    ) {
        config.backdrop = "static";
        config.keyboard = false;
        this.accessPrivCate = AccCategoryE;
        this.rxPrivs = RxE;
        this.editRxPrivs = EditRxE;
        this.rxEntryPrivs = RxEntryE;
        this.patientPrivs = PatientFileE;
        this.prescPrivs = PrescriberFileE;
        this.drugPrivs = DrugFileE;
    }

    ngOnInit() {
       this.prefDrugSetting =  +this._commonServ.getSetttingValue("RxSettings", "PRF_DRUG_SLCT_ON_RX_FILL");
       this.revAllowDays = this._commonServ.getSetttingValue("RxSettings", "REV_ALLOW_DAYS");
        this.CompDrugFG = this._fb.group({
            compDrugType: [],
            compSearchText: []
        });
        this.getRxVerifyDetails(this.erxData.ID);
        this.getControlSettings(); // change to behaviour subject
        this.defaultDrugEditQty = this._fb.group({
            selectedDrugQuantity: []
        });
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "equivalentDrugWj") {
                this.patchDefaultValHeaders();
            }
        });
        this.bucketId = this._commonServ.getSetttingValue("RxSettings", "DEF_GENERAL_BUCKET") ? +this._commonServ.getSetttingValue("RxSettings", "DEF_GENERAL_BUCKET") : null;
    }

    getSystemData() {
        this._commonServ.systemData$.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            this.systemData = resp;
        });
    }

    getRxVerifyDetails(XmlFileId: number) {
        // this._rphService.getRxVerifyDetails(XmlFileId, this.eRxVersion).subscribe(resp => {
            if (this.detailsInfo) {
                if (this.detailsInfo && this.detailsInfo.RxOrdDt) {
                    this.rxOrdDt = moment.utc(this.detailsInfo.RxOrdDt).local().format("MM/DD/YYYY");
                }
                if (this.detailsInfo && this.detailsInfo.EffectiveDate) {
                    this.EfctDt = moment.utc(this.detailsInfo.EffectiveDate).local().format("MM/DD/YYYY");
                }
                // this.detailsInfo = resp;
                this.responseState = this.detailsInfo.PrsOrdRef;
                if (this.responseState && this.responseState.includes("EPCS")) {
                    this.responseState = this.erxData.Status + " " + "EPCS VERIFIED";
                } else {
                    this.responseState = this.erxData.Status + this.erxData.RefillResponseStatus;
                }
                if (this.eMessageType == "CancelRx" || (this.eMessageType == "RxChangeResponse" && this.detailsInfo.rxInformtion && this.detailsInfo.rxInformtion.length > 0)) {

                    if (this.detailsInfo.OriginalRxDetails) {
                        this.cancelOrgRxInfo = this.detailsInfo.OriginalRxDetails;
                    } else if(this.detailsInfo.rxInformtion) {
                        this.cancelOrgRxInfo = this.detailsInfo.rxInformtion;
                    }
                    if (this.cancelOrgRxInfo) {
                        this.modelRef = this._modalService.open(
                            this.CancelReqTemp,
                            {
                                size: "lg",
                                centered: true,
                                windowClass: "md-x-lg",
                                keyboard: false
                            }
                        );
                    } else {
                        this.closeModal(true);
                    }
                } else  {
                    this.proceedForErxToNewRxScreen();
                }
            }
            // else {
            //     this.detailsInfo = null;
            // }
        // });
    }

    proceedForErxToNewRxScreen() {
        this.getErxIds(this.erxData.ID);
        this.patientInputValue =
            (this.detailsInfo && this.detailsInfo.PatLastName ? this.detailsInfo.PatLastName.toUpperCase() : "") +
            (this.detailsInfo && this.detailsInfo.PatFirstName ? ", " + this.detailsInfo.PatFirstName.toUpperCase() : "");
        this.prescriberInputValue =
            (this.detailsInfo && this.detailsInfo.PresLastName ? this.detailsInfo.PresLastName.toUpperCase() : "") +
            (this.detailsInfo && this.detailsInfo.PresFirstName ? ", " + this.detailsInfo.PresFirstName.toUpperCase() : "");
        this.drugInputValue = this.detailsInfo && this.detailsInfo.DrugName.split(" ")[0] ? this.detailsInfo.DrugName.split(" ")[0].toUpperCase() : "";
        if (this.detailsInfo && this.detailsInfo?.RefillNum != null){
            this.detailsInfo.auth = this.eMessageType === "REFRES" && +(this.detailsInfo?.RefillNum) > 0 ? (+(this.detailsInfo?.RefillNum) - 1) : +(this.detailsInfo?.RefillNum);
            this.detailsInfo.totalDispensings = this.detailsInfo?.auth ? (+(this.detailsInfo?.auth) + 1) : 1;
        }
            

    }

    eRxToNewRxPopupOpening() {
        if(this.modelRef ) {
            this.modelRef.close();
            this.modelRef  = null;
        }
        this.modelRef = this._modalService.open(
            this.PatPreDrugCheck,
            {
                size: "lg",
                backdrop: false,
                windowClass: "md-xx-lg comp--modal",
                keyboard: false,
            }
        );
    }

    async getErxIds(XmlFileId: number) {
        // const resp = await this._rphService.GetERxIds(XmlFileId).toPromise();
        this.rxFG = await this._nrxUtils.createPatchStoreRxFG();
        if (this.processIdsObj) {
            // this.processIdsObj = resp;
            let resp = this.processIdsObj;
            this.fileId = resp.Id;
            if (resp && resp.PatientId) {
                this.PatientId = resp.PatientId;
                await this.getPatientById(this.PatientId);
            }
            if (resp && resp.PrescriberId) {
                this.PrescriberId = resp.PrescriberId;
                await this.getPrescriberById(this.PrescriberId);
            }
            const presName = (this.prescriberInfo?.prescriberlastname ? this.prescriberInfo?.prescriberlastname.toLowerCase() : null) +
                " " + ((this.prescriberInfo?.prescriberfirstname) ? this.prescriberInfo?.prescriberfirstname.toLowerCase() : null);
            this.isPrescrNameNotMatched = (this.detailsInfo?.PrescName.toLowerCase() === presName);
            this.isPrescriberDEANotMatched = this.prescriberInfo?.prescriberdeanum?.toUpperCase() === this.detailsInfo?.PrescDea?.toUpperCase();
            this.prescPhoneNumCheck = this.prescriberInfo?.PrescriberAddresses?.find((item: any) => {return ((item.SPINum == this.detailsInfo?.PrescSpi) && item.Telephone !== this.detailsInfo?.PrescPhone)});
            this.prescAddressDetails = this.prescriberInfo?.PrescriberAddresses?.find((item: any) => {return ((item.SPINum == this.detailsInfo?.PrescSpi) && !((item.AddressLine1?.trim().toUpperCase() == this.detailsInfo?.PresAddrL1?.trim().toUpperCase()) && (item.AddressLine2?.trim().toUpperCase() == this.detailsInfo?.PresAddrL2?.trim().toUpperCase()) && (item.ZipCode?.trim() == this.detailsInfo?.PresZip?.trim())))});
            this.isSPIMatched = this.prescriberInfo?.PrescriberAddresses?.find((item: any) => {return (item.SPINum == this.detailsInfo?.PrescSpi)});
            if (resp && resp.DrugId && this.detailsInfo && !this.detailsInfo.isCompoundDrug) {
                this.eRxDurgId = resp.DrugId;
                this.DrugId = resp.DrugId;
                if (resp.PatientId && resp.PrescriberId && !((this.prescPhoneNumCheck || this.prescAddressDetails || !this.isPrescrNameNotMatched || !this.isPrescriberDEANotMatched || !this.isSPIMatched) || ((this.detailsInfo?.PatPhone && (this.rxPatientModel?.telephone?.trim() != this.detailsInfo?.PatPhone?.trim())) ||
                (this.detailsInfo?.PatAddrL1 && (this.rxPatientModel?.address1?.trim().toUpperCase() != this.detailsInfo?.PatAddrL1?.trim().toUpperCase())) ||
                (this.detailsInfo?.PatAddrL2 && (this.rxPatientModel?.address2?.trim().toUpperCase() != this.detailsInfo?.PatAddrL2?.trim().toUpperCase())) ||
                (this.detailsInfo.PatZip && (this.rxPatientModel?.zipcode?.trim() != this.detailsInfo?.PatZip?.trim()))))) {
                    await this.getDrugById(this.DrugId, true);
                } else {
                    await this.getDrugById(this.DrugId, false);
                   // this.checkForPhoneMisMatch();
                }
                
            } else if (this.detailsInfo && this.detailsInfo.isCompoundDrug) {
                await this.getCompoundDrugsByIngredients();
                //this.checkForPhoneMisMatch();
            }
           // this.checkForPhoneMisMatch();
            this.checkMissingTab();
            if (resp && this.detailsInfo && !resp.PatientId) {
                this.eRxToNewRxPopupOpening();
                if (this.PrescriberId && (this.prescPhoneNumCheck || this.prescAddressDetails || !this.isPrescrNameNotMatched || !this.isSPIMatched || !this.isPrescriberDEANotMatched)) {
                    this.isPrescriberMatched = false;
                }
            } else if (this.detailsInfo && this.rxPatientModel && resp.PatientId &&
                ((this.detailsInfo?.PatPhone && (this.rxPatientModel?.telephone?.trim() != this.detailsInfo?.PatPhone?.trim())) ||
                (this.detailsInfo?.PatAddrL1 && (this.rxPatientModel?.address1?.trim().toUpperCase() != this.detailsInfo?.PatAddrL1?.trim().toUpperCase())) ||
                (this.detailsInfo?.PatAddrL2 && (this.rxPatientModel?.address2?.trim().toUpperCase() != this.detailsInfo?.PatAddrL2?.trim().toUpperCase())) ||
                (this.detailsInfo.PatZip && (this.rxPatientModel?.zipcode?.trim() != this.detailsInfo?.PatZip?.trim())))
            ) {
                this.eRxToNewRxPopupOpening();
                if (this.PrescriberId && (this.prescPhoneNumCheck || this.prescAddressDetails || !this.isPrescrNameNotMatched || !this.isSPIMatched || !this.isPrescriberDEANotMatched)) {
                    this.isPrescriberMatched = false;
                }
                this.checkForPhoneMisMatch()
            } else if (resp && this.detailsInfo && !resp.PrescriberId) {
                this.eRxToNewRxPopupOpening();
            } else if (this.PrescriberId && this.detailsInfo && (this.prescPhoneNumCheck || this.prescAddressDetails || !this.isPrescrNameNotMatched || !this.isSPIMatched || !this.isPrescriberDEANotMatched)) {
                this.eRxToNewRxPopupOpening();
                this.checkForPrescriberDeaAndPhone();
            } else if (resp && this.detailsInfo && !(resp.PatientId && resp.PrescriberId && !this.detailsInfo.isCompoundDrug)) {
                this.eRxToNewRxPopupOpening();
            } else if(resp && !resp.DrugId && this.detailsInfo && !this.detailsInfo.isCompoundDrug){
                this.generateRxToProcess();
            }
        } else {
            this._alertService.error("eRx Id fetch unsuccessful.");
            this.closeModal(true);
        }
    }

    CloseWindowWhenFocusOnWijmo(event) {
        if (event.ctrlKey) {
            if (event.which === 67) {
                event.preventDefault();
                this.closeModal(true);
            }
        }
    }

    closeModal(cancelErxProcess?) {
        if (cancelErxProcess) {
            this._erxService.updateErxProcessStatus(this.erxData.ErxOperationId, false).pipe(takeUntil(this.unsubscribe$)).subscribe(statusUpdateResponse => {
                if (this.modelRef) {
                    this.modelRef.close();
                    this.modelRef = null;
                }
                this.IsPopUpClosed.emit(this.IsFromBefitCheck);
            });
        } else {
            if (this.modelRef) {
                this.modelRef.close();
                this.modelRef = null;
            } 
            this.IsPopUpClosed.emit(null);
        }
    }
    closemodal1() {
        if (this.modelRef1) {
            this.modelRef1.close();
            this.modelRef1 = null;
        }
    }

    closeModalRef2() {
        if (this.modalRef2) {
            this.modalRef2.close();
            this.modalRef2 = null;
                if(this.actModal2 == "patAddMatch") {
                    this.actModal2 = null;
                    this.checkFocus();
                    // this.checkForPrescriberDeaAndPhone(this.index);
                } else {
                    this.actModal2 = null;
                    this.newSPIAddingConfirmation();
                    // this.getEquivalentDrugsById(this.DrugId,this.skipPopUp, true);
                }
        }
    }

    updatePatientDetails() {
        let dataToPost = {
            Phones: [{ xPhone: null, IsActive: true }],
            Addresses: { AddressLine1: null, AddressLine2: null, ZipCode: null, IsActive: true },
            Patient: { Id: this.rxPatientModel.patientid }
        };
        if (this.detailsInfo) {
        if (this.rxPatientModel && this.detailsInfo.PatPhone && (this.rxPatientModel.telephone != this.detailsInfo.PatPhone)) {
            dataToPost.Phones[0].xPhone = this.detailsInfo.PatPhone;
        }
        if (this.detailsInfo.PatAddrL1) {
            dataToPost.Addresses.AddressLine1 = this.detailsInfo.PatAddrL1;
        }
        if (this.detailsInfo.PatAddrL2) {
            dataToPost.Addresses.AddressLine2 = this.detailsInfo.PatAddrL2;
        }
        if (this.detailsInfo.PatZip) {
            dataToPost.Addresses.ZipCode = this.detailsInfo.PatZip;
        }
        }
        let patientUpdateFG = {
            AddressLine1: this.rxPatientModel?.address1,
            AddressLine2: this.rxPatientModel?.address2 ? this.rxPatientModel?.address2 : null,
            ZipCode: this.rxPatientModel?.zipcode,
            State: this.rxPatientModel?.state,
            City: this.rxPatientModel?.city,
            Telephone: this.rxPatientModel?.telephone
        };
        this._erxService.updatePatientDetails(dataToPost).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    let updatedPatient = {
                        AddressLine1: resp.address1,
                        AddressLine2: resp?.address2 ? resp.address2 : null,
                        ZipCode: resp?.zipcode,
                        State: resp?.state,
                        City: resp?.city,
                        Telephone: resp?.telephone
                    };
                    this._auditUtils.getChangedValues(
                        patientUpdateFG,
                        updatedPatient,
                        "EditPatient",
                        "Patient",
                        this.rxPatientModel.patientid,
                    );
                    this.rxPatientModel = resp;
                    this.isPatientMatched = true;
                    this.closeModalRef2();
                    this.checkForPrescriberDeaAndPhone();
                    this.checkFocus()
                }
            });
    }

    checkForPhoneMisMatch() {
        const sysSetting = this._commonServ.getSetttingValue(
            "ERxSettings",
            "PROMPT_FOR_ERX_PATIENTINFO_UPDATE_IF_CHANGED"
        );
        if(!this.PatientValidationCompleted) {
            if (this.PatientId && this.rxPatientModel) {
                this.PatientValidationCompleted = true;
                if (sysSetting == "1") {
                    const patName = ((this.rxPatientModel.lastname) ? this.rxPatientModel.lastname.toLowerCase() : null) +
                        " " + ((this.rxPatientModel.firstname) ? this.rxPatientModel.firstname.toLowerCase() : null);
                    const pipe = new DatePipe("en-US");
                    const patDOBFromErx = pipe.transform(this.detailsInfo.PatBirthDt, "MM/dd/yyyy");
                    const patDOB = pipe.transform(this.rxPatientModel.dob, "MM/dd/yyyy");
                    if (this.detailsInfo && this.detailsInfo.PatName && patName && patDOB && patDOBFromErx && !(this.detailsInfo.PatName.toLowerCase() === patName && patDOB === patDOBFromErx)) {
                            this.isPatientMatched = false;
                            this.actModal2 = "patNotMatch";
                            this.modalRef2 = this._modalService.open(this.PatNotMatch, {keyboard: false,
                                    backdrop: false,centered: true, windowClass: "large--content"});
                    } else if (this.detailsInfo && this.detailsInfo.PatName && patName && patDOB && patDOBFromErx &&
                        (this.detailsInfo.PatName.toLowerCase() === patName && patDOB === patDOBFromErx)) {
                        if (this.detailsInfo && this.rxPatientModel &&
                            ((this.detailsInfo.PatPhone && (this.rxPatientModel?.telephone?.trim() != this.detailsInfo?.PatPhone?.trim())) ||
                            (this.detailsInfo.PatAddrL1 && (this.rxPatientModel?.address1?.trim().toUpperCase() != this.detailsInfo?.PatAddrL1?.trim().toUpperCase())) ||
                            (this.detailsInfo.PatAddrL2 && (this.rxPatientModel?.address2?.trim().toUpperCase() != this.detailsInfo?.PatAddrL2?.trim().toUpperCase())) ||
                            (this.detailsInfo.PatZip && (this.rxPatientModel?.zipcode?.trim() != this.detailsInfo?.PatZip?.trim())))
                        ) {
                            this.actModal2 = "patAddMatch";
                            this.index = 0;
                            this.modalRef2 = this._modalService.open(this.PatPhoneAddMatch, { keyboard: false, centered: true,
                                backdrop: false, windowClass: "z-index-1055 large--content" });
                            this.isPatientMatched = false;
                            this.checkFocus();
                        } else {
                            this.isPatientMatched = true;
                            this.checkForPrescriberDeaAndPhone(this.index);
                        }
                    } else {
                        this.isPatientMatched = true;
                        this.checkForPrescriberDeaAndPhone(this.index);
                    }
                } else {
                    this.checkForPrescriberDeaAndPhone(this.index);
                }
            }
        }
    }

    checkForPrescriberDeaAndPhone(index?) {
        const presName = (this.prescriberInfo?.prescriberlastname ? this.prescriberInfo?.prescriberlastname.toLowerCase() : null) +
                " " + ((this.prescriberInfo?.prescriberfirstname) ? this.prescriberInfo?.prescriberfirstname.toLowerCase() : null);
            this.isPrescrNameNotMatched = (this.detailsInfo?.PrescName.toLowerCase() === presName);
            this.isPrescriberDEANotMatched = this.prescriberInfo?.prescriberdeanum?.toUpperCase() === this.detailsInfo?.PrescDea?.toUpperCase();
            this.prescPhoneNumCheck = this.prescriberInfo?.PrescriberAddresses?.find((item: any) => {return ((item.SPINum == this.detailsInfo?.PrescSpi) && item.Telephone !== this.detailsInfo?.PrescPhone)});
            this.prescAddressDetails = this.prescriberInfo?.PrescriberAddresses?.find((item: any) => {return ((item.SPINum == this.detailsInfo?.PrescSpi) && !((item.AddressLine1?.trim().toUpperCase() == this.detailsInfo?.PresAddrL1?.trim().toUpperCase()) && (item.AddressLine2?.trim().toUpperCase() == this.detailsInfo?.PresAddrL2?.trim().toUpperCase()) && (item.ZipCode?.trim() == this.detailsInfo?.PresZip?.trim())))});
            this.isSPIMatched = this.prescriberInfo?.PrescriberAddresses?.find((item: any) => {return (item.SPINum == this.detailsInfo?.PrescSpi)});
        if(!this.prescriberVlidationCompleted)
        {
            if (this.detailsInfo && this.detailsInfo.PrescNpi && this.prescriberInfo && this.prescriberInfo.prescribernpinum &&
                !(this.detailsInfo.PrescNpi.toString() == this.prescriberInfo.prescribernpinum.toString())
            ){
                this.isPrescriberMatched = false;
                this.actModal2 = "preNotMatch";
                this.modalRef2 = this._modalService.open(this.PreNotMatch, {keyboard: false,
                    backdrop: false, windowClass: "large--content",centered: true});
            } else if (this.PrescriberId && this.detailsInfo && this.prescriberInfo && this.prescriberInfo.prescribernpinum ===  this.detailsInfo.PrescNpi) {
                if (this.prescPhoneNumCheck || this.prescAddressDetails || !this.isPrescrNameNotMatched || !this.isPrescriberDEANotMatched) {
                    this.isPrescriberMatched = false;
                    this.actModal2 = "presDeaMatch";
                    if(!index)
                        this.index = 1;
                    this.actModal2 = "PrescribDeaMatch";
                    this.modalRef2 = this._modalService.open(this.PrescribDeaMatch, { keyboard: false, centered: true,
                    backdrop: false, windowClass: "z-index-1055 large--content" });
                    this.checkFocus();
                } else if (!this.isSPIMatched) {
                    this.newSPIAddingConfirmation();
                } else {
                    this.isPrescriberMatched = true;
                    this.getEquivalentDrugsById(this.DrugId, this.skipPopUp,true);
                }
            }
            this.prescriberVlidationCompleted = true;
        }
    }

    newSPIAddingConfirmation() {
        if (!this.isSPIMatched) {
            this.modalRef2 = this._modalService.open(this.SPInotFound, { keyboard: false, centered: true,
                backdrop: false, windowClass: "z-index-1055 large--content" });
            this.isPrescriberMatched = false;
            this.actModal2 = "presSPIMatch";
            this.index = 1;
        }
        this.checkFocus();
    }

    SPIUpdation() {
        let clinicalFG = this._fb.group({
            PrescriberId: this.prescriberInfo.prescriberid,
            Address: this._fb.group(new PresClinicalAddress()),
            PrescAdds: this._fb.group(new PrescAdds())
        });
        clinicalFG.controls["Address"].patchValue({
            IsActive: true,
            AddressLine1: this.detailsInfo?.PresAddrL1,
            AddressLine2: this.detailsInfo?.PresAddrL2,
            CityName: this.detailsInfo?.PresCity,
            SPINum: this.detailsInfo?.PrescSpi,
            StateName: this.detailsInfo?.PresState,
            Telephone: this.detailsInfo?.PrescPhone,
            ZipCode: this.detailsInfo?.PresZip,
            Fax: this.detailsInfo?.PrescFax
        });
        clinicalFG.controls["PrescAdds"].patchValue({
            SPINum: this.detailsInfo?.PrescSpi
        });
        this._prescService
                .saveClinicAdress(clinicalFG.value)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp && resp !== 2 && resp !== 3) {
                        this._alertService.success(MsgConfig.SAVE_SUCCESS);
                        this._auditUtils.getChangedValues(
                            null,
                            clinicalFG.value,
                            "Add Clinical Address",
                            "Prescriber",
                            this.prescriberInfo.prescriberid
                        );
                        this.modalRef2?.close();
                        this.isPrescriberMatched = true;
                        this.checkFocus();
                    }
                });
    }

    updatePrescriberDetails() {
        let dataToPost = {
            prescriberid: this.prescriberInfo.prescriberid,
            telephone: this.detailsInfo.PrescPhone,
            prescriberdeanum: this.detailsInfo.PrescDea,
            prescriberaddress1: this.detailsInfo?.PresAddrL1,
            prescriberaddress2: this.detailsInfo?.PresAddrL2,
            prescriberzipcode: this.detailsInfo?.PresZip,
            prescribercity: this.detailsInfo?.PresCity,
            prescriberstate: this.detailsInfo?.PresState,
            prescriberspinum: this.detailsInfo.PrescSpi,
            prescriberfirstname: this.detailsInfo?.PresFirstName,
            prescriberlastname: this.detailsInfo?.PresLastName
        };
        let prescAddressDetails = this.prescriberInfo?.PrescriberAddresses?.find((item: any) => {return (item.SPINum == this.detailsInfo?.PrescSpi)})
        let prescriberUpdateFG = {
            DEAReg: this.prescriberInfo.prescriberdeanum,
            Address1: prescAddressDetails?.AddressLine1,
            Address2: prescAddressDetails?.AddressLine2 ? prescAddressDetails?.AddressLine2 : null,
            ZipCode: prescAddressDetails?.ZipCode,
            State: prescAddressDetails?.StateName,
            City: prescAddressDetails?.CityName,
            telephone: prescAddressDetails?.['Telephone'],
            FirstName: this.prescriberInfo?.prescriberfirstname,
            LastName: this.prescriberInfo?.prescriberlastname
        };
        this._erxService.updatePrescriberDetails(dataToPost).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp) {
                this.prescriberInfo = resp;
                let prescAddressDetails = this.prescriberInfo?.PrescriberAddresses?.find((item: any) => {return (item.SPINum == this.detailsInfo?.PrescSpi)});
                let updatedPrescriber = {
                    DEAReg: resp.prescriberdeanum,
                    Address1: prescAddressDetails?.AddressLine1,
                    Address2: prescAddressDetails?.AddressLine2 ? prescAddressDetails?.AddressLine2 : null,
                    ZipCode: prescAddressDetails?.ZipCode,
                    State: prescAddressDetails?.StateName,
                    City: prescAddressDetails?.CityName,
                    telephone: prescAddressDetails?.['Telephone'],
                    FirstName: resp?.prescriberfirstname,
                    LastName: resp?.prescriberlastname
                };
                this._auditUtils.getChangedValues(
                    prescriberUpdateFG,
                    updatedPrescriber,
                    "EditPrescriber",
                    "Prescriber",
                    this.prescriberInfo.prescriberid,
                );
                this.isPrescriberMatched = true;
                this.closeModalRef2();
                this.checkFocus();
            }
        });
    }

    gotoNext(val?) {
        if (this.modalRef2) {
            this.modalRef2.close();
        }
        if (this.index === 0) {
            if(this.actModal2 === "patNotMatch"){
                this.actModal2 = null;
                this.isFrmPatientChange = true;
                this.overrideOnPatientPrescriberChange("Patient","PatientChange");
            } else{
                this.openOverride = false;
                if (val) {
                    if (this.modelRefChange) {
                        this.modelRefChange.close();
                    }
                }
                this.isFrmPatientChange = false;
                this.index = 1;
                this.checkFocus();
                if (this.inactivePresc) {
                    this._alertService.error("Selected Prescriber  is not an Active Prescriber. Please select a different Prescriber." , false, "long");
                }
            }
        } else if (this.index === 1) {
            if(this.actModal2 === "preNotMatch"){
                this.actModal2 = null;
                this.isFrmPatientChange = true;
                this.overrideOnPatientPrescriberChange("Prescriber","PrescriberChange");
            } else{
                this.openOverride = false;
                if (val) {
                    if (this.modelRefChange) {
                        this.modelRefChange.close();
                    }
                }
                this.isFrmPatientChange = false;
                this.index = 2;
                this.checkFocus();
                if (this.isDrugInactive) {
                    this._alertService.error("Selected Drug  is not an Active Drug. Please select a different Drug.", false, "long");
                }
            }
        }
    }

    overrideOnPatientPrescriberChange(CategoryType, type) {
        this.modelRefChange = this._modalService.open(OverrideLogComponent, {
            centered: true,
            keyboard: false, backdrop: false, windowClass:'medium-modal-50'
        });
        this.modelRefChange.componentInstance.PrivMask = type;
        this.modelRefChange.componentInstance.CatId = CategoryType;
        this.modelRefChange.componentInstance.Consolidated = true;
        this.modelRefChange.componentInstance.IsFrmInfo = true;
        this.modelRefChange.componentInstance.Comp = true;
        this.modelRefChange.componentInstance.RxInfo = this.rxFG.value;
        this.modelRefChange.componentInstance.RxFG = this.rxFG;
        this.modelRefChange.componentInstance.IsNotesDisplay = true;
        this.modelRefChange.result.then(res => {
            if (!res && type == 'PatientChange') {
                this.PatientId = null
            } else if (!res && type == 'PrescriberChange') {
                this.PrescriberId = null
            } else {
                // this.gotoNext(res);
            }
        });
        this.categoryId = CategoryType;
        this.privType = type;
        this.openOverride = true;
}

  next() {
        this.isInputFocused = false;
        if (this.index === 0) {
                this.index = 1;
                this.checkFocus();
                if (this.inactivePresc) {
                    this._alertService.error("Selected Prescriber  is not an Active Prescriber. Please select a different Prescriber." ,
                     false, "long");
               } else {
                    this.prescriberVlidationCompleted = false;
                    this.checkForPrescriberDeaAndPhone();
               }
        } else if (this.index === 1) {
                this.index = 2;
                this.checkFocus();
                if (this.isDrugInactive) {
                    this._alertService.error("Selected Drug  is not an Active Drug. Please select a different Drug.", false, "long");
                } else if(this.detailsInfo && !this.detailsInfo.isCompoundDrug){
                   this.generateRxToProcess();
                }
        } else if (this.index === 2) {
            const checkSelectedDrugNDc = (this.detailsInfo && this.detailsInfo.DrugNdc) ?
                 this.detailsInfo.DrugNdc.toString().toLowerCase() : true;
            const checkDrugNDc = (this.drugInfo && this.drugInfo.ndc) ? this.drugInfo.ndc.toString() : true;
            if ((this.detailsInfo && this.detailsInfo.isCompoundDrug) || checkSelectedDrugNDc === checkDrugNDc
            ) {
                this.generateRxToProcess();
            } else if (this.detailsInfo && this.equivalentDrugWJ && this.equivalentDrugWJ.items &&
                 this.equivalentDrugWJ.items.some(val => val["ndc"] === this.detailsInfo.DrugNdc)) {
                this.generateRxToProcess();
            } else {
                this.generateRxToProcess();
            }
        }
    }
    previous() {
        this.index--;
    }

    gotoPage(targetIndex, currentIndex) {
        if ((currentIndex > targetIndex && currentIndex !== targetIndex) ||
            targetIndex === 0 ||
            (targetIndex === 1 && this.PatientId && this.rxPatientModel) ||
            (targetIndex === 2 && this.PatientId && this.rxPatientModel && this.PrescriberId && this.prescriberInfo)) {
            this.index = targetIndex;
        } else if (targetIndex === 1) {
            this._alertService.error("Please select Patient.");
        } else if (targetIndex === 2) {
            let miss = [];
            !this.PatientId ? miss.push("Patient") : null;
            !this.PrescriberId ? miss.push("Prescriber") : null;
            this._alertService.error("Please select " + miss.join(",") + ".");
        }
    }

    checkifInactive() {
        if (this.inactivePat) {
            this.index = 1;
        } else if (this.inactivePresc) {
            this.index = 2;
        }
    }

    checkMissingTab() {
        this.isInputFocused = false;
        if (this.PatientId) {
            this.index = 1;
            if (this.PrescriberId) {
                this.index = 2;
                if (this.DrugId) {
                    this.index = 2;
                    // this.checkRxGeneration();
                }
            }
        }
        this.checkFocus();
    }

    async getPatientById(patId: any) {
        if (patId) {
            const resp = await this._commonServ.getRxPatientDetailsById(patId).toPromise();
            if (resp) {
                this.rxPatientModel = resp;
                if (this.rxPatientModel.actstatus !== 1) {
                    this._alertService.error("Selected Patient is not an Active Patient. Please select a different Patient."
                        , false, "long");
                    this.rxPatientModel = null;
                    this.patientInputValue = "";
                    this.index = 0;
                    this.PatientId = null;
                    this.inactivePat = true;
                    this.checkFocus();
                } else {
                    // this.checkForPhoneMisMatch();
                    this.checkFocus();
                    this.inactivePat = false;
                }
            }
        }
    }

    selectedPatientInfo(patientInfo: any) {
        if (patientInfo) {
            if (patientInfo["actstatus"] !== 1) {
                this._alertService.error("Selected Patient is not an Active Patient. Please select a different Patient."
                    , false, "long");
            } else {   
                this.rxPatientModel = patientInfo;
                this.PatientId = patientInfo.patientid;
                this.patientInputValue = null;
                this.isInputFocused = false;
                this.inactivePat = false;
                this.checkFocus();
                this.PatientValidationCompleted = false;
                this.checkForPhoneMisMatch();
            }
        }
    }

    selectedPresInfo(prescInfo: any) {
        if (prescInfo) {
            if (prescInfo["activestatusid"] !== 1) {
                this._alertService.error("Selected Prescriber  is not an Active Prescriber. Please select a different Prescriber."
                    , false, "long");
            } else {
                this.prescriberInfo = prescInfo;
                this.PrescriberId = prescInfo.prescriberid;
                this.prescriberInputValue = null;
                this.inactivePresc = false;
                this.isInputFocused = false;
                this.prescriberVlidationCompleted = false;
                this.checkForPrescriberDeaAndPhone();
                this.checkFocus();
            }
        }
    }

    async getPrescriberById(presId: any) {
        if (presId) {
            const resp = await this._commonServ.getRxPrescriberDetailsById(presId).toPromise();
            if (resp) {
                if (resp["activestatusid"] !== 1) {
                    this.prescriberInfo = null;
                    this.inactivePresc = true;
                    this.isInputFocused = true;
                    this.PrescriberId = null;
                } else {
                    this.prescriberInfo = resp;
                    this.inactivePresc = false;
                    this.isInputFocused = false;
                    this.checkFocus();
                }
            }
        }
    }

    selectDrugAndCheckEquiv(selecteDrug) {
        if (selecteDrug) {
            this.selectedDrugInfo = selecteDrug;
            this.tempEqDrug = null;
            if (this.prescribedEqDrugs && this.prescribedEqDrugs.some(val => val.ndc === selecteDrug.ndc)) {
                this.getDrugById(selecteDrug.id);
            } else {
                this.tempEqDrug = selecteDrug;
                this.actModal2 = "DrugNotEquiv";
                this.modalRef2 = this._modalService.open(this.selcDrugNotEqui, { keyboard: false, centered: true,
                     backdrop: false, windowClass: "large--content"});
            }
        }
    }

    drugFormBarcode(event) {
        this.isDrugFormBarcode = event; 
    }

    async getDrugById(drugId: any, skipPopUp?, equivalentDrugsReloadNotRequired?: boolean, doNotconsiderPreferredDrugsetting?: boolean, isFrmPopUp? ) {
        this.DrugId = drugId;
        if (drugId && this.detailsInfo && !(this.detailsInfo.isCompoundDrug)) {
            const resp = await this._commonServ.getRxDrugDetailsById(drugId).toPromise();
            if (resp) {
                this.drugInputValue = "";
                if (!resp["isactive"]) {
                    this.isDrugInactive = true;
                    if(this.isDrugFormBarcode) { 
                        this.drugInfo = this.selectedDrugInfo; 
                        this.drugInfo['IsManualDrugEnter'] = false; 
                    } else {
                        this.drugInfo = resp; 
                        this.drugInfo['IsManualDrugEnter'] = true;
                    }
                    if(!equivalentDrugsReloadNotRequired)
                        this.getEquivalentDrugsById(drugId, null, null, doNotconsiderPreferredDrugsetting, isFrmPopUp? isFrmPopUp: true);
                    this.isInputFocused = true;
                } else {
                    this.isDrugInactive = false;
                    if(!equivalentDrugsReloadNotRequired)
                        this.getEquivalentDrugsById(drugId, skipPopUp, null, doNotconsiderPreferredDrugsetting, isFrmPopUp? isFrmPopUp: true);
                    if(this.isDrugFormBarcode) { 
                        this.drugInfo = this.selectedDrugInfo; 
                        this.drugInfo['IsManualDrugEnter'] = false; 
                    } else {
                        this.drugInfo = resp; 
                        this.drugInfo['IsManualDrugEnter'] = true;
                    }
                    this.isInputFocused = false;
                    this.checkFocus();
                }
            }
        } else {
            this.getCompoundDrugsByIngredients();
        }
    }

    getCompoundIngredsById(compDrugId) {
        this._drugService.getDrugIngredientsById(compDrugId, this.eRxVersion).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp) {
                // this.compAddedNew = true;
                this.selectedCompoundDrug = resp;
                this.DrugId = this.selectedCompoundDrug.Id;
                this.addedCompDrug = resp;
                this.getCompoundDrugsByIngredients(true);
            }
        });
    }

    setCompPage(eve) {
        this.compDrugPageNumber = eve;
        this.getCompoundDrugsByIngredients();
    }

    async getCompoundDrugsByIngredients(optDefult?) {
        let dataToPost = {
            ndcData: this.detailsInfo.compoundIngredients.every(elm => { return elm.DrugNdc != null }) ? this.detailsInfo.compoundIngredients.map(elm => elm.DrugNdc) : null,
            drugCount: this.detailsInfo.compoundIngredients.length,
            drugNames: this.detailsInfo.compoundIngredients.map(elm => elm.DrugName),
            searchText: this.CompDrugFG.value.compSearchText,
            pageNumber: this.compDrugPageNumber,
            pageSize: this.pageSize
        };
        const resp = await this._erxService.getCompoundDrugs(dataToPost, this.eRxVersion).toPromise();
        if (resp) {
            this.totalCompoundDrugs = resp;
            if (this.totalCompoundDrugs && this.totalCompoundDrugs.remainingDrugs &&
                this.totalCompoundDrugs.remainingDrugs.length > 0) {
                this.compTotalCount = this.totalCompoundDrugs.remainingDrugs[0].TotalCount;
            }
            if (this.addedCompDrug && this.totalCompoundDrugs.suggestedDrugs && this.totalCompoundDrugs.suggestedDrugs.length > 0
                && this.totalCompoundDrugs.suggestedDrugs.some(val => val.DrugCode === this.addedCompDrug.DrugCode)) {
                this.CompDrugFG.controls.compDrugType.patchValue("Similar");
                this.optedCompDrug("Similar");
                this.compTotalCount = this.totalCompoundDrugs.suggestedDrugs[0].TotalCount;
            } else if (this.addedCompDrug) {
                this.CompDrugFG.controls.compDrugType.patchValue("All");
                this.optedCompDrug("All");
            }
            if (optDefult) {
                this.compList.focus();
                return;
            }
            if (this.totalCompoundDrugs.suggestedDrugs && this.totalCompoundDrugs.suggestedDrugs.length &&
                (this.defaultSimilarDrug || this.CompDrugFG.controls.compDrugType.value === "Similar")) {
                this.CompDrugFG.controls.compDrugType.patchValue("Similar");
                this.optedCompDrug("Similar");
                this.defaultSimilarDrug = false;
            } else {
                this.CompDrugFG.controls.compDrugType.patchValue("All");
                this.optedCompDrug("All");
            }
        }
    }

    optedCompDrug(optedValue) {
        this.compAddedNew = false;
        if (this.CompDrugFG.value.compDrugType === "Similar") {
            this.compSelctionList = this.totalCompoundDrugs?.suggestedDrugs;
            this.compTotalCount = (this.totalCompoundDrugs?.suggestedDrugs && this.totalCompoundDrugs?.suggestedDrugs?.length > 0) ?
                                    this.totalCompoundDrugs?.suggestedDrugs[0].TotalCount : 0;
        } else {
            this.compSelctionList = this.totalCompoundDrugs?.remainingDrugs;
            this.compTotalCount = (this.totalCompoundDrugs?.remainingDrugs && this.totalCompoundDrugs?.remainingDrugs?.length > 0) ?
                                    this.totalCompoundDrugs?.remainingDrugs[0].TotalCount : 0;
        }
    }

    showIngreds(s: any, e: any) {
        this.compAddedNew = false;
        // const ht = s.hitTest(e);
        if (s.selectedRows[0].dataItem) {
            this.selectedCompoundDrug = s.selectedRows[0].dataItem;
            this.DrugId = this.selectedCompoundDrug.Id;
        }
        e.preventDefault();
    }

    saveDrugSuggestVal(drugSuggestVal) {
        let callEquSev = false;
        if ((drugSuggestVal && this.drugSuggestDet &&
            drugSuggestVal["ShowNonSplittableEquiDrugs"] !== this.drugSuggestDet["ShowNonSplittableEquiDrugs"]) ||
            (drugSuggestVal && !this.drugSuggestDet)) {
                callEquSev = true;
        }
        this.drugSuggestDet = drugSuggestVal;
        if (callEquSev) {
            this.getEquivalentDrugsById(this.DrugId,null,null,null,true);
        }
    }

    async getEquivalentDrugsById(drugId: any, skipPopUp?, skipDataComparision?, doNotconsiderPreferredDrugsetting?,ispopupOpen?) {
        const resp = (this.equivalentList && !doNotconsiderPreferredDrugsetting) ? this.equivalentList : await this._drugService.getEquivalentDrug(drugId, this.detailsInfo.DrugNdc, true,1,
            null, this.drugSuggestDet, doNotconsiderPreferredDrugsetting).toPromise();
        if (resp && resp.length > 0) {
                if (!this.DrugId && this.processIdsObj.DrugCount === 0) {
                }
                this.equivalentList = resp;
                this.eqDrugsSize = resp.length;
                if(resp && resp.length) {
                 this.prefDrugList =    resp.filter((item: any) => { return item.ispreferred });
                 this.prefDrugSize = this.prefDrugList.length;
                }
                const days = +this._commonServ.getSetttingValue("RxSettings", "WARN_DCN_DRUG_GRACE_PERIOD");
                resp.map(drug => {
                    const isDrugDiscontinued = drug["discontdt"] && moment().startOf("day")
                        .isAfter(moment.utc(drug["discontdt"]).local().add(days, "days"));
                    drug["isdiscontdate"] = isDrugDiscontinued ? "YES" : "NO";
                });
        }
               if(this.prefDrugSize == 1 && (this.prefDrugSetting == 2) && resp) {
                     resp.sort((a, b) => (a.ispreferred > b.ispreferred) ? -1 : 1)
               }
            this.equivalentDrugWJ = new CollectionView(resp);
            this.generateEquivalentDrugWJ(resp);
            if(resp && resp.length > 0 && !ispopupOpen) {
                this.drugInfo = resp[0];
                this.DrugId = this.drugInfo.id;
            }
            this.hasPrefDrug = false;
            if ((resp && resp.length > 0) && (resp.some(elm => elm.ispreferred))) {
                this.hasPrefDrug = true;
            }
            // resp.forEach(element => {
            //     if (element && element.ispreferred && !this.hasPrefDrug) {
            //         this.hasPrefDrug = true;
            //     }
            // });
            if (this.processIdsObj.DrugId === drugId) {
                this.prescribedEqDrugs = resp;
            }
            this.skipPopUp = skipPopUp;
            // this.patientOrPrescriberPopupOpenedInRedirectionFlow = true;
            if (this.actModal2 === "patAddMatch") {
                if (this._commonServ.getSetttingValue("ERxSettings", "SET_YES_AS_DEFAULT_FOR_PATIENT_INFO_UPDATE") == "1") {
                    if (document.getElementById("updatePatienInfo")) {
                        document.getElementById("updatePatienInfo").focus();
                    }
                } else {
                    if (document.getElementById("cancelPatienInfo")) {
                        document.getElementById("cancelPatienInfo").focus();
                    }
                }
            } else if (this.actModal2 === "presDeaMatch") {
                if (document.getElementById("cancelPresInfo")) {
                    document.getElementById("cancelPresInfo").focus();
                }
            }
            else if (skipPopUp && resp && resp.length == 1 && resp[0].ispreferred || ((this.equivalentList && this.equivalentList.length) && (this.actModal2 !== "PrescribDeaMatch" && this.actModal2!== "presSPIMatch" && this.PatientId && this.PrescriberId && this.DrugId && this.index === 2) )) {
                this.drugInfo = resp[0];
                this.DrugId = this.drugInfo.id;
                this.generateRxToProcess();
            } else if (skipPopUp) {
                if(this.modelRef ) {
                    this.modelRef.close();
                    this.modelRef  = null;
                }
                this.generateRxToProcess();
            }
            this.checkFocus();
            // need to implement
    }

    checkFocus() {
        setTimeout(() => {
            if (this.index === 0) {
                if (this.actModal2 === "patAddMatch") {
                    if (this._commonServ.getSetttingValue("ERxSettings", "SET_YES_AS_DEFAULT_FOR_PATIENT_INFO_UPDATE") == "1") {
                        if (document.getElementById("updatePatienInfo")) {
                            document.getElementById("updatePatienInfo").focus();
                        }
                    } else {
                        if (document.getElementById("cancelPatienInfo")) {
                            document.getElementById("cancelPatienInfo").focus();
                        }
                    }
                } else if (this.actModal2 === "patNotMatch") {
                    if (document.getElementById("patientNotMatch")) {
                        document.getElementById("patientNotMatch").focus();
                    }
                }else {
                    if (!this.PatientId || this.inactivePat) {
                        this.isInputFocused = true;
                    } else {
                        if (document.getElementById("nxtBtn")) {
                            document.getElementById("nxtBtn").focus();
                        }
                    }
                }
            } else if (this.index === 1) {
                if(this.actModal2 === "preNotMatch"){
                    if (document.getElementById("prescriberNotMatch")) {
                        document.getElementById("prescriberNotMatch").focus();
                    }
                } else if(this.actModal2 === "PrescribDeaMatch") {
                    if (document.getElementById("PrescribDeaMatch")) {
                        document.getElementById("PrescribDeaMatch").focus();
                    }
                } else if(this.actModal2 === "presSPIMatch") {
                    if (document.getElementById("SPINotMatch")) {
                        document.getElementById("SPINotMatch").focus();
                    }
                } else{
                    if (!this.PrescriberId || this.inactivePresc) {
                        this.isInputFocused = true;
                    } else {
                        if (document.getElementById("nxtBtn")) {
                            document.getElementById("nxtBtn").focus();
                        }
                    }
                }
            } 
            else if (this.index === 2) {
                if (!this.DrugId || this.isDrugInactive) {
                    this.isInputFocused = true;
                } else if (this.eqqWijmo && this.eqDrugsSize > 0) {
                    this.eqqWijmo.focus();
                } else {
                    if (document.getElementById("nxtBtn")) {
                        document.getElementById("nxtBtn").focus();
                    }
                }
            }
        }, 500);
    }

    selectedDrug(isChecked: boolean, checkedVal: any, equivalentDrugsReloadNotRequired?: boolean) {
        if (isChecked) {
            this.DrugId = checkedVal["id"];
            this.getDrugById(this.DrugId, null, equivalentDrugsReloadNotRequired);
        }
    }

    onRowDblclicked(s: any, e: any) {
        let drugInfo = s.selectedRows[0].dataItem;
        this.DrugId = drugInfo.id;
        this.getDrugById(drugInfo.id, null, true);
        e.preventDefault();
    }

    init(flex: wjcGrid.FlexGrid) {
        this.eqqWijmo = flex;
        flex.columnHeaders.rows[0].height = 70;
        flex.columnHeaders.rows[0].wordWrap = true;
    }

    initCompDrugs(flex: wjcGrid.FlexGrid) {
        this.compList = flex;
    }

    // checkRxGeneration() {
    //     if (this.PatientId && this.PrescriberId && this.DrugId) {
    //         if (
    //             this.drugInfo
    //         ) {
    //             if (this.inactivePat || this.actModal2 == "patAddMatch") {
    //                 this.index = 0;
    //             } else if (this.inactivePresc) {
    //                 this.index = 1;
    //             } else {
    //                 this.index = 2;
    //             }
    //         } else {
    //             this.generateRxToProcess();
    //         }
    //     }
    // }

    generateRxToProcess() {
        //this.IsPopUpClosed.emit(null)
        if(this.drugInfo){
            const prescribedDrug = this.drugInfo['isbranded'];
            const eqDrugsPrescribedDrugList = (this.prefDrugList && this.prefDrugList.length)  ? (this.prefDrugList.filter((item: any) =>  (item && (prescribedDrug === item['isbranded']))  )) : null;
                if (this.PatientId && this.PrescriberId && this.DrugId) {
                    if (this.detailsInfo && this.detailsInfo.isCompoundDrug) {
                        this.checkDrugQuantity();
                    } 
                    // else if ( eqDrugsPrescribedDrugList && eqDrugsPrescribedDrugList.length &&
                    //     ((this.prefDrugSetting == 2 && this.prefDrugSize && this.prefDrugSize > 1) ||
                    //  (this.prefDrugSetting  == 1 )) && this.drugInfo &&  !this.drugInfo.ispreferred && this.hasPrefDrug && this.eqDrugsSize > 0
                    // ) {
                    //     const modalRef: any = this._modalService.open(EquivalentDrugsComponent,
                    //         { backdrop: false, size: "lg", keyboard: false, centered : true});
                    //     modalRef.componentInstance.EqulDrugPopType = "eRx";
                    //     modalRef.componentInstance.EquivalentDrugWJ =  new CollectionView(eqDrugsPrescribedDrugList);
                    //     modalRef.componentInstance.DiscDrugInfo = {
                    //         drugname : this.detailsInfo.DrugName ,
                    //         drugcode : this.detailsInfo.DrugNdc,
                    //         isbranded: this.drugInfo.isbranded
                    //     };
                    //     modalRef.componentInstance.emitValuesForeRx.pipe(takeUntil(this.unsubscribe$)).subscribe(async resp => {
                    //         modalRef.close();
                    //         if(resp && (resp !== 'IGNORE' && resp !== 'Cancel')) {
                    //            await this.selectedDrug(true, resp, true);
                    //             this.checkDrugQuantity();
                    //         } else if(resp && (resp === 'IGNORE')) {
                    //             this.checkDrugQuantity();
                    //         }
                    //     });
                    // }  
                    else {
                        this.checkDrugQuantity();
                    }
                } else {
                this.checkDrugQuantity();  
                }
        }else {
            this.checkDrugQuantity();
            
        }
    }

    checkDrugQuantity() {
        if (this.detailsInfo && this.detailsInfo.isCompoundDrug) {
            if (this.selectedCompoundDrug && +this.selectedCompoundDrug.DefaultQty &&
                +this.selectedCompoundDrug.DefaultQty !== +this.detailsInfo.RxQty) {
                this.defaultDrugEditQty.controls.selectedDrugQuantity.patchValue(+this.detailsInfo.RxQty);
                this.actModal2 = "drugQuantity";
                this.modalRef2 = this._modalService.open(this.DrugQuantity, {
                    keyboard: false,
                    centered: true, backdrop: false,
                    windowClass: "large--content"
                });
            } else {
                this.checkDefaultSig();
            }
        } else {
            this.checkDefaultSig();
        }
    }

    checkDefaultSig() {
        this.createSigFGPatchValues();
        if (this.detailsInfo && this.detailsInfo.isCompoundDrug) {
            if (this.selectedCompoundDrug && this.selectedCompoundDrug.DefaultSigCode &&  this.detailsInfo.RxSig) {
                this.actModal2 = "defaultSig";
                this.modalRef2 = this._modalService.open(this.SelectDefaultSig, {
                    keyboard: false,
                    centered: true
                });
            } else {
                this.checkMaxRefillsForDrug();
            }
        } else {
            this.checkMaxRefillsForDrug();
        }
    }

    checkMaxRefillsForDrug(sigToConsider?: number) {
        this.SigFG.controls.SelectedSig.patchValue(sigToConsider);
        let drugClass;
        if (this.detailsInfo && this.detailsInfo.isCompoundDrug &&  this.selectedCompoundDrug) {
            drugClass = this.selectedCompoundDrug.DrugClass + "";
        } else {
            drugClass = this.drugInfo?.drugclass + "";
        }
        if (this.controlRefillSettings) {
            this.refillSettingLimit = this.controlRefillSettings.find(x => x["DrugClass"] === drugClass);
            if (this.refillSettingLimit && this.refillSettingLimit["MaxRefillAllow"] !== this.erxData["Refill"]) {
                if (this.refillSettingLimit["MaxRefillAllow"] < this.erxData["Refill"] && this.detailsInfo.isCompoundDrug) {
                    this.actModal2 = "MaxRefillsPopup";
                    this.modalRef2 = this._modalService.open(this.MaxRefillsForDrugClass, {
                        keyboard: false,
                        centered: true, backdrop: false,
                        windowClass: "large--content"
                    });
                } else {
                    this.checkIsPrn();
                }
            } else {
                this.checkIsPrn();
            }
        } else {
            this.checkIsPrn();
        }
    }

    getControlSettings() {
        this._ctrlFileSvc.getControlFileSettings().pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp && resp["ControlRefillOptions"]) {
                this.controlRefillSettings = resp["ControlRefillOptions"];
            }
        });
    }

    checkIsPrn() {
        let authRefillsCount = 0;
        let drugClass;
        if (this.detailsInfo && this.detailsInfo.isCompoundDrug &&  this.selectedCompoundDrug) {
            drugClass = +this.selectedCompoundDrug.DrugClass;
        } else {
            drugClass = +this.drugInfo?.drugclass;
        }
        if (drugClass >= 2 && drugClass <= 5) {
            authRefillsCount = this.refillSettingLimit["MaxRefillAllow"];
        } else {
            authRefillsCount = 99;
        }
        for (let i = 0; i <= authRefillsCount; i++) {
            this.authRefills.push({ Id: i, Name: i });
        }
        if (this.detailsInfo && this.detailsInfo.RefillNum === "PRN" && this.detailsInfo.isCompoundDrug) {
            this.actModal2 = "isPrn";
            this.modalRef2 = this._modalService.open(this.IsPrn, { centered: true, keyboard: false,
                backdrop: false, windowClass: "large--content" });
            setTimeout(() => {
                if (document.getElementById("rxReFillNum")) {
                    const element: any = document.getElementById("rxReFillNum").getElementsByTagName("input")[0];
                    element.focus();
                }
            }, 100);
        } else {
            this.generateRxtoProcess();
        }
    }

    createSigFGPatchValues() {
        this.SigFG = this._fb.group({
            RxSig: [this.detailsInfo.RxSig ? this.detailsInfo.RxSig : null],
            DefaultSigCode: [this.detailsInfo.isCompoundDrug ? ( this.selectedCompoundDrug?.DefaultSigCode ? this.selectedCompoundDrug.DefaultSigCode : null)  : (this.drugInfo && this.drugInfo["defaultSigCode"] ? this.drugInfo["defaultSigCode"] : null)],
            DefaultSig: [],
            AlternateSig: [],
            IsPrn: [ this.detailsInfo.RefillNum ? (this.detailsInfo.RefillNum === "PRN" ? true : false ) : false],
            AuthRefills: [0],
            SelectedSig:[]
        });
        if (this.detailsInfo && this.detailsInfo.isCompoundDrug) {
            this.getSigCodeInfo(this.selectedCompoundDrug?.DefaultSigCode ? this.selectedCompoundDrug.DefaultSigCode : null);
        } else {
            this.getSigCodeInfo(this.drugInfo?.["defaultSigCode"]);
        }
    }

    getSigCodeDescOnChange(sigC: any) {
        const endSpace = /\s$/;
        if (sigC && endSpace.test(sigC)) {
            this.getSigCodeInfo(sigC);
        }
    }

    getSigCodeInfo(val) {
        if (val) {
            val = val.toLowerCase();
            const sigCodeList: string[] = val.split(" ");
            const sigData = {
                sigcodes: sigCodeList,
                langcodes: ["enhyus", "enhyus"]
            };
            this._rxService
                .getSigCodeListByLangCode(sigData).pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp && Object.keys(resp).length > 0) {
                        let sigCodeData = "";
                        let sigAltCodeData = "";
                        sigCodeList.map(code => {
                            for (const key in resp) {
                                if (code === key) {
                                    if (resp[key]) {
                                        if (resp[key]["en-US"]) {
                                            const sigCodeDS = resp[key][
                                                "en-US"
                                            ].split("#");
                                            sigCodeData += " " + sigCodeDS[0];
                                        } else {
                                            sigCodeData += " " + key;
                                        }
                                    } else {
                                        sigCodeData += " " + key;
                                        sigAltCodeData += " " + key;
                                    }
                                }
                            }
                        });
                        this.SigFG.controls.DefaultSig.patchValue(sigCodeData);
                    } else {
                        this.SigFG.controls.DefaultSig.patchValue(val);
                    }
                });
        }
    }

    selectDrugQuantityAndProceed(){
        if (this.defaultDrugEditQty.valid) {
            if (this.modalRef2) {
            this.modalRef2.close();
            }
            this.actModal2 = null;
            this.checkDefaultSig();
        } else {
            this._alertService.error("Please select drug quantity.");
            return;
        }
    }

    generateRxtoProcess() {
        if (this.checkHasPrivsToAccess(
            this.accessPrivCate.Rx,
            this.rxPrivs.RxEntry
        )) {
            this.generateRx();
        } else {
            this.openOveride("Rx", "RxEntry", "POST_Rx");
        }
    }

    generateRx() {
        let dataToPost = {
            ErxId: this.erxData.ID,
            ErxOpTransactionId: this.erxData.EROPTransId,
            PatientId: this.PatientId,
            PrescriberId: this.PrescriberId,
            DrugId: this.DrugId,
            Id: this.fileId,
            ErxOperationId: this.erxData.ErxOperationId,
            IsPrn: this.SigFG.value.IsPrn,
            AuthRefills: this.SigFG.value.AuthRefills,
            SigDesc: null,
            AltDesc: null,
            qtyDispensed: this.defaultDrugEditQty.value.selectedDrugQuantity,
            eRxDetailsViewModel:this.detailsInfo
        };
        let sigSelected = null;
        if (this.SigFG.value.SelectedSig === 1) {
            dataToPost.SigDesc = this.SigFG.value.RxSig;
            sigSelected = "RxSig";
        } else if (this.SigFG.value.SelectedSig === 2) {
            dataToPost.SigDesc = this.SigFG.value.DefaultSig;
            dataToPost.AltDesc = this.SigFG.value.AlternateSig;
            sigSelected = "DrugSig";
        }
        this._erxService.generateRxToProcess(dataToPost, this.erxData.Version).pipe(takeUntil(this.unsubscribe$)).subscribe(async resp => {
            if (resp) {
                this.IsFromBefitCheck = false;
                this.closeModal();
                this.IsPopUpClosed.emit(null);
                if(!this.FromIntakeQueue)
                      this.IsPopUpClosed1.emit(false);
                if (this.drugInfo && this.drugInfo.drugclass) {
                    this.detailsInfo.DrugClass = this.drugInfo.drugclass;
                }
                sessionStorage.setItem("ErxType", this.eMessageType);
                sessionStorage.setItem("ErxSig", sigSelected);
                let selectedQty = null;
                if (this.selectedCompoundDrug) {
                    if (+this.selectedCompoundDrug.DefaultQty !== +this.detailsInfo.RxQty) {
                        if (+this.defaultDrugEditQty.value.selectedDrugQuantity === +this.selectedCompoundDrug.DefaultQty) {
                            selectedQty = "DrugQty";
                        } else {
                            selectedQty = "RxQty";
                        }
                    }
                } else if (this.drugInfo && this.detailsInfo && +this.drugInfo.defaultqty !== +this.detailsInfo.RxQty) {
                    if (+this.defaultDrugEditQty.value.selectedDrugQuantity === +this.drugInfo.defaultqty) {
                        selectedQty = "DrugQty";
                    } else {
                        selectedQty = "RxQty";
                    }
                }
                sessionStorage.setItem("ErxQty", selectedQty);
                localStorage.setItem(
                    "patientId",
                    this._commonSvc.encryptData(JSON.stringify(resp.Patient.patientid))
                );
                // new rx redirection logic

                // let rxFG = await this._nrxUtils.createPatchStoreRxFG();
                this.rxFG.controls["routeFrom"].setValue("/eprx/eRX/eRxActionList");
                this.rxFG = this._patchRx.patchRxInfo(resp, this.rxFG);
                this.rxFG.controls.Prescription.patchValue({
                    ReFillNum : (this.rxFG.value.eRxDetailsViewModel?.MsgType === "RxRenewalResponse" && this.rxFG.value.Prescription.ReFillNum > 0 ? this.rxFG.value.Prescription.ReFillNum - 1 : 
                    this.rxFG.value.Prescription.ReFillNum)
                });
                this._rxStore.StoreRxFG(this.rxFG);
                // this.patchInv340BSettInfo(rxFG);
                //rxFG.controls.prescReFill.patchValue({BucketId: this._drugBuckUtil.getDefaultBucketId()});
                const inprgrsArray = this._inprService.inProgressRxs$["source"]["value"];
                if(inprgrsArray && inprgrsArray.length > 0) {
                        let index = inprgrsArray.findIndex((item: any) => {
                            return  item["eRxDetailsViewModel"] && item["eRxDetailsViewModel"]["ErxId"] && item["eRxDetailsViewModel"]["ErxId"] === resp["eRxDetailsViewModel"]["ErxId"]
                        });
                        if (index > -1) {
                            inprgrsArray.splice(index, 1);
                            this._inprService.storeProgressData(inprgrsArray);
                        }
                }
                this._router.navigate(["eprx/dashboard"]);
                setTimeout(() => {
                    this._router.navigate(["/eprx/rx"], {
                        queryParams: { fm: "nr"}
                    });
                },0);
                // this._rxUtils.storeERXInfo(resp, null, "nr");
            } else {
                this._alertService.error(
                    MsgConfig.PAT_PRE_DRUG_CHECK_NEWRX_GENER_FAIL
                );
            }
        });
    }

    // patchInv340BSettInfo(rxFG: FormGroup) {
    //     const PATIENT: FormGroup = rxFG.get("Patient") as FormGroup;
    //     const PRESCRIBER: FormGroup = rxFG.get("Prescriber") as FormGroup;
    //     const DRUG: FormGroup = rxFG.get("Drug") as FormGroup;
    //     const PrescReFill: FormGroup = rxFG.get("PrescReFill") as FormGroup;
    //     const checkPatSlctdExcludedIns: boolean = !this._drugBuckUtil.checkPatSlctdExcludedIns(PATIENT, rxFG);
    //     if (!PrescReFill.value.Is340B && PATIENT.value.Is340B && checkPatSlctdExcludedIns &&
    //         this._drugBuckUtil.get340BSettingInfo("patient")) { // patient 340b settings
    //         this.setRxAs340BRx(rxFG, true);
    //         return;
    //     }
    //     if (!PrescReFill.value.Is340B) { // prescriber 340b settings.
    //         const checkPrescriber340OrNot: boolean = this._drugBuckUtil.checkPrescriber340OrNot(PRESCRIBER);
    //         const inv340BSettingForprescriber: boolean = this._drugBuckUtil.get340BSettingInfo("prescriber");
    //         const checkInsurance340BOrNot: boolean = this._drugBuckUtil.checkInsurance340BOrNot(PATIENT, rxFG);
    //         if (checkPrescriber340OrNot && checkPatSlctdExcludedIns && inv340BSettingForprescriber) {
    //             // if 340b prescriber and 340b ins then marks rx as 340b.
    //             if (checkInsurance340BOrNot && this._drugBuckUtil.get340BSettingInfo("AUTOMARK340B")) {
    //                 this.setRxAs340BRx(rxFG, true);
    //                 return;
    //             } else {
    //                 this.setRxAs340BRx(rxFG, true);
    //                 return;
    //             }
    //         }
    //     }
    //     if (!PrescReFill.value.Is340B && checkPatSlctdExcludedIns && DRUG.value.Is340B) { // drug 340b Settings info.

    //     }
    // }

    setRxAs340BRx(rxFG: FormGroup, set340B: boolean = true) { // setting rx as 340b rx.
        this._drugBuckUtil.setRxAs340bRx(rxFG, set340B);
    }

    mergeObjects(obj1, obj2) {
        for (const key in obj2) {
            if (typeof (obj2[key]) === "object" && !Array.isArray(obj2[key])) {
                this.mergeObjects(obj1[key], obj2[key]);
            } else if (typeof (obj2[key]) === "object" && Array.isArray(obj2[key])) {
                if ((!obj1[key] || obj1[key].length === 0) && obj2[key] && obj2[key].length > 0) {
                    obj1[key] = obj2[key];
                }
            } else {
                if (obj2[key]) {
                    obj1[key] = obj2[key];
                }
            }
        }
    }

    // OverrideRes(val) {
    //     this.openOverride = false;
    //     if (val) {
    //         this.generateRx();
    //     } else {
    //         this.closeModal(true);
    //     }
    // }

    // openOveride(content) {
    //     this.privType = content;
    //     this.categoryId = "Rx";
    //     this.openOverride = true;
    // }

    checkHasPrivsToAccess(category: string, privType: string) {
        return this._privMaskGuard.canActivate(category, privType);
    }


    OverrideRes(val) {
        this.openOverride = false;
        if (this.overrideFor === "Rx") {
            if (val) {
                this.generateRx();
            } else {
                this.closeModal(true);
            }
        } else if (val) {
            if (this.overrideFor === "PatientFile") {
                this.addNewPatPresDrug("Patient");
            } else if (this.overrideFor === "PrescriberFile") {
                this.addNewPatPresDrug("Prescriber");
            } else if (this.overrideFor === "DrugFile") {
                this.addNewPatPresDrug("Drug");
            }
        }
    }

    openOveride(category, type, endpoint?) {
        this.categoryId = category;
        this.privType = type;
        this.openOverride = true;
        this.overrideFor = category;
        this.overrideEndpoint = endpoint;
    }
    addNewPatPresDrug(addNew: string) {
        this.isInputFocused = false;
        if (addNew === "Patient") {
            if (!this.checkHasPrivsToAccess(this.accessPrivCate.PatientFile, this.patientPrivs.Add) && !this.alreadyPrivCheck) {
                this.alreadyPrivCheck = true;
                this.openOveride("PatientFile", "Add", 'POST_Patient');
            } else {
                this.PatientId = null;
                const modalRefPAt = this._modalService.open(PatientComponent, {
                    backdrop: false,
                    size: "lg",
                    windowClass: "md-x-lg erx-to-add-modal",
                    keyboard: false,
                    centered: true
                });
                const patFG:FormGroup = this._patUtils.generatePatientInfoFG();
                this._patUtils.patchPatientFrmErx(this.detailsInfo, patFG);
                modalRefPAt.componentInstance.addPatientModel = patFG;
                modalRefPAt.componentInstance.FrmErx = true;
                modalRefPAt.componentInstance.erxData =   this.detailsInfo;
                modalRefPAt.componentInstance.ClosePatientModal.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
                    modalRefPAt.close();
                    this.patientInputValue = null;
                    this._cdr.detectChanges();
                    if (res && res.Type !== "Cancel") {
                        this.PatientId = res.PatientId;
                        this.getPatientById(res.PatientId);
                        if(!sessionStorage.getItem("ProcessingeRxsRecords"))
                             this.RefreshGrid.emit(null);
                    } else {
                        // this._alertService.error( MsgConfig.PAT_PRE_DRUG_CHECK_PAT_CREATE_CANCEL);
                    }
                    this.checkFocus();
                });
            }
        } else if (addNew === "Prescriber" || this.index == 1) {
            if (!this.checkHasPrivsToAccess(this.accessPrivCate.PrescriberFile, this.prescPrivs.Add) && !this.alreadyPrivCheck) {
                this.alreadyPrivCheck = true;
                this.openOveride("PrescriberFile", "Add", 'POST_Prescriber');
            } else {
                let formGroupName = null;
                if (addNew != "Prescriber") {
                    formGroupName = this._prescriberUtil.generatePrescriberFG();
                    if (/^([0-9]{10})$/.test(addNew)) {
                        formGroupName.controls["Person"].patchValue({
                            NPINum: addNew
                                ? addNew
                                : null,
                        });
                    } else if (
                        /^([a-zA-Z]{2})([0-9]{7})$/.test(addNew)
                    ) {
                        formGroupName.controls["Person"].patchValue({
                            DEANum: addNew,
                        });
                    } else if (addNew.indexOf("/") === -1) {
                        const patientNames = addNew.split(",");
                        formGroupName.controls["Person"].patchValue({
                            LastName: patientNames[0]
                                ? patientNames[0].trim()
                                : null,
                            FirstName: patientNames[1]
                                ? patientNames[1].trim()
                                : null,
                        });
                    }
                }
                this.PrescriberId = null;
                const modalRefPresc = this._modalService.open(PrescriberComponent, {
                backdrop: false,
                size: "lg",
                windowClass: "md-x-lg erx-to-add-modal",
                keyboard: false,
                centered: true
                });
                const prescFG = this._prescriberUtil.generatePrescriberFG();
                this._prescriberUtil.patchErxValues(this.detailsInfo, prescFG);
                modalRefPresc.componentInstance.addPrescriberFG = addNew != "Prescriber" ? formGroupName : prescFG;
                modalRefPresc.componentInstance.openMMS = addNew != "Prescriber" ? true : false;
            // modalRefPresc.componentInstance.DefPrescrName = this.detailsInfo;
            // modalRefPresc.componentInstance.IsFromErx = true;
                modalRefPresc.componentInstance.ClosePrescriberModal.pipe(takeUntil(this.unsubscribe$)).subscribe(response => {
                this.prescriberInputValue = "";
                this._cdr.detectChanges();
                modalRefPresc.close();
                if (response && response.Type !== "Cancel") {
                    this.PrescriberId = response.prescriberId;
                    this.getPrescriberById(this.PrescriberId);
                    if(!sessionStorage.getItem("ProcessingeRxsRecords"))
                        this.RefreshGrid.emit(null);
                } else {
                    // this._alertService.error( MsgConfig.PAT_PRE_DRUG_CHECK_PRE_CREATE_CANCEL);
                }
                this.checkFocus();
                });
            }
        } else if (addNew === "Drug") {
            if (!this.checkHasPrivsToAccess(this.accessPrivCate.DrugFile, this.drugPrivs.Add) && !this.alreadyPrivCheck) {
                this.alreadyPrivCheck = true;
                this.openOveride("DrugFile", "Add", 'POST_Drug_New');
            } else {
                const modalRefDrug = this._modalService.open(DrugComponent, {
                    backdrop: false,
                    size: "lg",
                    windowClass: "md-xx-lg comp--modal",
                    keyboard: false,
                    centered: true
                });
                const drugFG: FormGroup = this._drugUtil.createDrugDataModal();
                this._drugUtil.patchErxValues(this.detailsInfo, drugFG);
                modalRefDrug.componentInstance.addDrugModal = drugFG;
                // modalRefDrug.componentInstance.DefDrugName = this.detailsInfo;
                modalRefDrug.componentInstance.IsFromErx = true;
                modalRefDrug.componentInstance.CloseDrugModal.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
                    modalRefDrug.close();
                    if (resp && resp.Type !== "Cancel") {
                        this.DrugId = null;
                        if (this.detailsInfo.isCompoundDrug) {
                            this.getCompoundIngredsById(resp.drugId);
                        } else {
                            this.DrugId = resp.drugId;
                            this.getDrugById(resp.drugId);
                            this.dawYaddingDrug = true;
                        }
                        if(!sessionStorage.getItem("ProcessingeRxsRecords"))
                            this.RefreshGrid.emit(null);
                    } else {
                        // this._alertService.error( MsgConfig.PAT_PRE_DRUG_CHECK_DRUG_CREATE_FAIL);
                    }
                    this.checkFocus();
                });
            }    
        }
        this.closemodal1()
    }

    openPatientHist(content) {
        this.actModal2 = "patHist";
        this.modalRef2 = this._modalService.open(content, {
            keyboard: false,
            centered: true,
            size: "lg",
            windowClass: "md-x-lg"
        });
    }

    // rx change Response

    discontinueAndCreateNew() {
        this.acceptCancelRx();
    }

    // cancel Rx

    openCancelPatHist() {
        this.resetCancelPatient();
        this.cancelRxInfo = [];
        this.actModal2 = "canPatHist";
        this.modalRef2 = this._modalService.open(this.SelectCancelRx, {
            keyboard: false,
            size: "lg",
            windowClass: "md-x-lg",
            centered : true
        });
    }

    selectdCancelPat(patientInfo:any) {
        this.cancelPatient = patientInfo;
    }

    resetCancelPatient() {
        this.cancelPatient = null;
        this.isInputFocused = false;
        setTimeout(() => {
            this.isInputFocused = true;
        }, 30);
    }

    getCancelRxInfo(rxInfo) {
        this.cancelRxInfo = rxInfo;
    }

    pickCancelRx() {
        if (this.cancelRxInfo && this.cancelRxInfo.length === 1) {
            const patName = ((this.cancelPatient && this.cancelPatient.lastname) ? this.cancelPatient.lastname.toLowerCase() : null) +
             " " + ((this.cancelPatient && this.cancelPatient.firstname) ? this.cancelPatient.firstname.toLowerCase() : null);
            const pipe = new DatePipe("en-US");
            const patDOBFromErx = pipe.transform(this.detailsInfo.PatBirthDt, "MM/dd/yyyy");
            const patDOB = pipe.transform(this.cancelPatient.dob, "MM/dd/yyyy");
            if (this.detailsInfo && this.detailsInfo.PatName && patName && patDOB && patDOBFromErx &&
                (this.detailsInfo.PatName.toLowerCase() === patName && patDOB === patDOBFromErx)
            ) {
                this._erxService.getRxRefillsByPrescnum(this.cancelRxInfo[0].Prescription.PrescNum).pipe(takeUntil(this.unsubscribe$))
                    .subscribe(resp => {
                        if (resp && resp.length > 0) {
                            this.cancelOrgRxInfo = resp;
                            this.closeModal2();
                        }
                    });
            } else {
                this._alertService.error("Patient mismatch with selected Rx");
            }
        } else if (this.cancelRxInfo && this.cancelRxInfo.length > 1) {
            this._alertService.error("Please Select only one Rx");
        } else {
            this._alertService.error("Please Select Rx");
        }
    }

    acceptCancelRx() {
        if (this.detailsInfo && this.detailsInfo.OriginalRxDetails && this.detailsInfo.OriginalRxDetails.length > 0) {
            this.accRejPopUpType = "Accept";
            this.accRejPopUp = this._modalService.open(this.AcceptRejectRx, { keyboard: false, centered: true });
            this.cancelPharmNotes = "Prescription never filled.";
        } else if (this.cancelOrgRxInfo && this.cancelOrgRxInfo.length > 0) {
            this.processForReverseAndDelete();
        }
    }

    rejectCancelRx() {
        this.accRejPopUpType = "Reject";
        this.accRejPopUp = this._modalService.open(this.AcceptRejectRx, { keyboard: false, centered: true });
    }

    processForReverseAndDelete() {
        this.sortedOrgInfo = Object.assign([], this.cancelOrgRxInfo);
        this.sortedOrgInfo.reverse();
        this.sortedOrgInfo.map(element => {
            element["IsSelected"] = true;
        });
        // this.checkForEnableCancelRx();
        this.reverseListModal = this._modalService.open(this.RevDelRx, {
            size: "lg",
            keyboard: false,
            centered: true
        });
    }

    closeReverseListModal() {
        if (this.reverseListModal) {
            this.reverseListModal.close();
            this.reverseListModal = null;
            this.hasPayRecords = false;
        }
    }

    revAndDelSelectedRxs() {
        this.rxsToProcess = this.cancelOrgRxInfo.filter(elem => elem["IsSelected"] === true);
        if (this.rxsToProcess && this.rxsToProcess.length > 0) {
            this.reverseNextRx();
        } else {
            this._alertService.error("No Rx(s) is selected for deletion, Original Rx will be discontinued");
        }
    }

    reverseNextRx() {
        this.paidPayRecords = [];
        this.currentProcessingRx = [];
        this.closeDeleteModal();
        if (this.rxsToProcess && this.rxsToProcess.length > 0) {
            this.rxsToProcess.map((i) => {
                this.currentProcessingRx.push(i);
                if (this._commonUtil.checkPayRecExistOrNotForSelcRx(i.RxPay)) {
                    this.paidPayRecords.push(i);
                }
            })
        }
        if (this.paidPayRecords && this.paidPayRecords.length > 0) {
            this.reverseModal = this._modalService.open(this.ReverseCancelRx, { centered: true, keyboard: false,
                backdrop: false, windowClass: "large--content"});
        } else {
            this.rxsToProcess && this.rxsToProcess.length ? this.isQtyDetected() : null;
        }
    }

    isQtyDetected() {
        this.closeReverseModal();
        if ((+this._commonSvc.getSetttingValue("InventorySettings", "Inventory_Calculation_Based_On_DPV")) == 1) {
            this.isDPVOffset = true;
        } else if ((+this._commonSvc.getSetttingValue("InventorySettings", "Consume_Unbilled_In_Inventory_Calculation")) == 1) {
            this.isUnbilledOffset = true;
        } else {
            this.isBilledOffset = true;
        }
        this.currentRx = this.rxsToProcess.shift();
        if (this.isUnbilledOffset) {
            return this.LabelConfirmation();
        } else if (this.isDPVOffset && this.currentRx && this.currentRx.drugPickVerificationStatus && this.currentRx.drugPickVerificationStatus.trim().toUpperCase() == "P") {
            return this.LabelConfirmation();
        } else if (this.isBilledOffset && this.currentRx && this.currentRx.RxBill && this.currentRx.RxBill.StatusId == 2) {
            this.LabelConfirmation();
        } else {
           return this.deleteNextRx();
        }
    }

    returnToStockLabel(type) {
        if (type === "printLabel") {
            this.printLabel(this.currentRx, type);
        }
    }

   async printLabel(rx, fromType?: any) {
       var isPopupVisible = true
       const rxNumber = rx.Prescription["PrescNum"];
       let rxInfo: any =  await this._nrxUtils.getandStoreRxInfo(rxNumber, rx.RxOtherAmount["PrescReFillId"]);
       this._cmmnPrntSrv.printLabelToRx(rxInfo, false, true, false, fromType, "cancelRx");
       this.subscriptions = this._cmmnPrntSrv.isLabelPrintCompleted().subscribe((res: any) => {
        if (res && res.fromScreen && res.fromScreen == "cancelRx") {
            isPopupVisible = false;
            this.deleteNextRx();
            this._cmmnPrntSrv.setPrintCompleted(null);
        }})
   }

    LabelConfirmation() {
        const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
            backdrop: false,
            keyboard: false, windowClass: "large--content"
        });
        modelRef.componentInstance.warnMsg = (`
        <div class="row">
            <div class="col-md-12">
                <span class="col-md-12">Do you wish to print the return to stock label?</span>
            </div>
            <div class="col-md-12">
                <span class="col-md-12">Rx#: <label>${ this.currentRx.Prescription?.PrescNum }-${ this.currentRx.PrescReFill?.ReFillNum} </label></span>
            </div>
        </div>`)
        modelRef.componentInstance.IsHeaderText = "Print label before Deleting?";
        modelRef.componentInstance.yesButton = true;
        modelRef.componentInstance.noButton = true;
        modelRef.componentInstance.IsPopUpClosed.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
            modelRef.close();
            if (resp) {
                this.returnToStockLabel('printLabel')
            } else {
                this.deleteNextRx();
            }
        });
    }

    pushToDeleteRx(rxToPush,isReverseTrasmit) {

        rxToPush.map((i) => {
            this.deleteRxs.push(i);
            this.reverseTrasmitArray.push(isReverseTrasmit);
        })

        if (!isReverseTrasmit) {
            this.sortedOrgInfo = [];
        }
    }

    checkExpiration(rxToPush, isReverseTrasmit) {
        rxToPush.map(i => {
            this.reverseRxs.push(i);
        })
        this.closeDeleteModal();
        if (this.paidPayRecords && this.paidPayRecords.length > 0) {
            rxToPush.map(i => {
                this.differenceDays = moment().diff(i.PrescReFill.FillDtTm, "days");
                if (this.differenceDays > +this.revAllowDays) {
                    this.expireDate.push(i);
                }
            })
        }
        if (this.expireDate && this.expireDate.length > 0) {
            this.expireDate = [];
            this._modalService.open(this.expiredToReverse, { centered: true, keyboard: false,
                backdrop: false, windowClass: "large--content"});
        } else {
            this.pushToDeleteRx(rxToPush, isReverseTrasmit);
            this.ContinueAcceptCancelRx()
        }

    }

    closeReverseModal() {
        if (this.reverseModal) {
            this.reverseModal.close();
            this.reverseModal = null;
        }
    }

    closeDeleteModal() {
        if (this.deleteModal) {
            this.deleteModal.close();
            this.deleteModal = null;
        }
    }

    // selectCheckChanged(cancelRxSing, value, index) {
    //     this.checkForEnableCancelRx();
    //     this.hasPayRecords = this._commonUtil.checkPayRecExistOrNotForSelcRx(cancelRxSing.RxPay) ? true : false;
    // }

    // checkForEnableCancelRx() {
    //     this.enableCancelRefillNo = null;
    //     let isChecked = false;
    //     this.sortedOrgInfo.forEach((cancelRefill, i) => {
    //         if (!cancelRefill.IsSelected && !((cancelRefill.pickupRx && cancelRefill.pickupRx.IsPickedUp) || (cancelRefill.rxDelivery && cancelRefill.rxDelivery.isShipped))) {
    //             if (!isChecked) {
    //                 isChecked = true;
    //                 this.enableCancelRefillNo = i;
    //             }
    //         }
    //     });
    //     let chked = false;
    //     this.sortedOrgInfo.slice(0).reverse().forEach((cancelRefill, i) => {
    //         if (cancelRefill.IsSelected) {
    //             if (!chked) {
    //                 chked = true;
    //                 this.enableDeselect = this.sortedOrgInfo.length - i - 1;
    //             }
    //         }
    //     });
    // }

    deleteNextRx() {
        this.closeReverseModal();
        if (this.rxsToProcess && this.rxsToProcess.length) {
            this.isQtyDetected();
        } else {
            if (this.subscriptions) {
                this.subscriptions.unsubscribe();
            }
            this.deleteModal = this._modalService.open(this.DeleteCancelRx, { centered: true, keyboard: false,
                backdrop: false, windowClass: "large--content"});
        }
    }

    ContinueAcceptCancelRx() {
        if (this.eMessageType === "RxChangeResponse") {
            this.reverseRefills();
        } else {
            this.accRejPopUpType = "Accept";
            this.cancelPharmNotes = this.cancelOrgRxInfo[0].Prescription.Remarks;
            if (this.sortedOrgInfo.length === 0) {
                this.cancelPharmNotes = "Original plus all refill(s) are cancelled";
            } else if (this.sortedOrgInfo.length === 1) {
                this.cancelPharmNotes = "Original Rx dispensed";
            }
            this.accRejPopUp = this._modalService.open(this.AcceptRejectRx, { keyboard: false, centered: true });
        }
    }

    cancelRxNotesChanged(eve) {
        if (eve) {
            this.cancelPharmNotes = eve;
        }
    }

    cancelRxReasonSelected(eve) {
        if (eve?.FieldVal) {
            this.cancelReason = eve;
        }
    }

    closeAccRejPopup() {
        this.RefreshGrid.emit(null);
        if (this.accRejPopUp) {
            this.accRejPopUp.close();
            this.accRejPopUp = null;
            this.accRejPopUpType = null;
        }
        this.closeReverseListModal();
    }

    closeModal2() {
        if (this.modalRef2) {
            this.modalRef2.close();
            this.modalRef2 = null;
            this.actModal2 = null;
        }
    }

    AcceptOrRejectCancelRx() {
        if ((!this.cancelReason || this.cancelReason.ValDescr.trim() === "N/A") && (!this.cancelPharmNotes || !this.cancelPharmNotes.length)) {
            this._alertService.error("Reason/Pharmacist notes is mandatory");
            return;
        }
        let erxStatus = "";
        if (this.sortedOrgInfo.length === 0 && this.accRejPopUpType === "Accept") {
            erxStatus = "Approved";
        } else if (this.accRejPopUpType === "Accept") {
            erxStatus = "ApprovedWithChanges";
        } else {
            erxStatus = "Denied";
        }

        let dataToPost = {
            prescRefills: "",
            // tslint:disable-next-line: max-line-length
            prescNum: (this.cancelOrgRxInfo && this.cancelOrgRxInfo.length > 0) ? (this.cancelOrgRxInfo[0].Prescription ? this.cancelOrgRxInfo[0].Prescription.PrescNum : null) : null,
            rxId: (this.cancelOrgRxInfo && this.cancelOrgRxInfo.length > 0) ? (this.cancelOrgRxInfo[0].Prescription ? this.cancelOrgRxInfo[0].Prescription.Id : 0) : 0,
            reasonCode: this.cancelReason ? (this.cancelReason.FieldVal ? this.cancelReason.FieldVal : "") : "",
            reasonDescription: this.cancelReason ? (this.cancelReason.ValDescr ?
                (this.cancelReason.ValDescr.trim() === "N/A" ? "" : this.cancelReason.ValDescr) : "") : "",
            notes: this.accRejPopUpType !== "Denied" ? this.cancelPharmNotes : null,
            erxId: this.erxData.ID,
            status: erxStatus,
            notProcessedErxId: this.detailsInfo.notProcessedErxId,
            ErxOperationId: this.erxData.ErxOperationId,
            processedRxErxId: (this.detailsInfo && this.detailsInfo.rxInformtion && this.detailsInfo.rxInformtion.length > 0) ? ((this.detailsInfo.rxInformtion[0].eRxDetailsViewModel && this.detailsInfo.rxInformtion[0].eRxDetailsViewModel.ErxId ) ? this.detailsInfo.rxInformtion[0].eRxDetailsViewModel.ErxId: null) : null
        };
        this._erxService.acceptOrRejectCancelRx(dataToPost,this.eRxVersion).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if(resp == 2) {
                    this._alertService.error("The eRx you are trying to process is already processed by another User");
                    this.closeAccRejPopup();
                    this.closeCancelRx();
                } else {
                    this.reverseRefills();
                }
            });
    }

    reverseRefills() {
        if (this.reverseRxs  && this.reverseRxs.length > 0) {
            let ids = this.reverseRxs.map(elem => { return elem.PrescReFill.Id });
            let dataToPost = {
                prescRefills: ids,
                prescNum: this.cancelOrgRxInfo[0].Prescription.PrescNum
            };
            this._erxService.reverseCancelRx(dataToPost).pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        this.deleteRefills();
                        this.closeAccRejPopup();
                        this.closeCancelRx();
                    }
                });
        } else if (this.accRejPopUpType === "Accept" && this.eMessageType !== "RxChangeResponse" &&
         (this.detailsInfo && (!this.detailsInfo.OriginalRxDetails ||
             (this.detailsInfo.OriginalRxDetails && !this.detailsInfo.OriginalRxDetails.length))) // if OriginalRxDetails is null or length 0
          && this.cancelOrgRxInfo && this.cancelOrgRxInfo.length > 0) {
            const ids = this.cancelOrgRxInfo.map(elem => { return elem.PrescReFill.Id });
            const dataToPost = {
                prescRefills: ids,
                prescNum: this.cancelOrgRxInfo[0].Prescription.PrescNum
            };
            this._erxService.reverseCancelRx(dataToPost).pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        this.closeAccRejPopup();
                        this.closeCancelRx();
                    }
                });
        } else {
            this.closeAccRejPopup();
            this.closeCancelRx();
        }
    }

    closeCancelRx() {
        if (this.eMessageType == 'RxChangeResponse') {
            if (this.modelRef) {
                this.modelRef.close();
                this.modelRef = null;
            }
            this.proceedForErxToNewRxScreen();
        } else {
            this.closeModal();
        }
    }

    deleteRefills() {
        const ids = this.deleteRxs.map(elem => elem.PrescReFill.Id);
        this.deleteRxs.map(elem => {
            const data = {
                PrescRefillId:elem.PrescReFill?.Id,
                RefillNum:elem.PrescReFill?.ReFillNum,
                FillNo: elem.PrescReFill.PartialFillNo
              }
              this.PrescRefInfo.push(data);
         });
        // tslint:disable-next-line: max-line-length
        const transmissionStatus = this.deleteRxs.map(elem => (elem.RxPay && elem.RxPay.some(ad => ad.RecType === "P") && elem.RxBill.StatusId === 2) ? true : false);
        const insurance = this.deleteRxs.map(elem => elem.insuranceInfo ? elem.insuranceInfo.insuCarrier.InsurerCode : "");
        const dataToPost = {
            PrescRefInfo: this.PrescRefInfo,
            prescRefills: ids,
            prescNum: this.cancelOrgRxInfo[0].Prescription.PrescNum,
            transmissionStatus: transmissionStatus,
            rxId: this.cancelOrgRxInfo[0].Prescription.Id,
            patientId: this.cancelOrgRxInfo[0].Prescription.PatientId,
            insCode: insurance,
            isForTransmitReversalOnly: this.reverseTrasmitArray
        };
        this.subscriptions = (this._erxService.deleteCancelRx(dataToPost)
            .subscribe(resp => {
                if (resp)
                {
                    if (this.subscriptions) {
                        this.subscriptions.unsubscribe()
                        this.subscriptions = new Subscription();
                    }
                }
            }));
    }

    printErxCompoundIngredients() {
        this._erxService.getErxCompoundIngredients(this.erxData.ID).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp && resp.size > 0 && resp.type === "application/pdf") {
                this.report = resp;
                this.export();
            } else {
                this._alertService.error(MsgConfig.REPORT_GENERATION_ERROR);
            }
        });
    }

    export() {
        const a: any = document.createElement("a");
        a.style = "display: none";
        const data: any = this.report;
        const url = URL.createObjectURL(data);
        a.href = url;
        a.download = "ErxCompoundIngredients.pdf" ;
        a.click();
    }

    generateEquivalentDrugWJ(eqDrgData) {
        if (eqDrgData && eqDrgData.length) {
            this.equivalentDrugWJSource = new CollectionView(
                eqDrgData.map((data: any, i) => {
                    const k = {};
                    k['id'] = data.id;
                    k["DrugClassDyn"] = (data.isdiscontdate === "YES" && data.ispreferred)? "card drug-card-red drug-card-brown" :
                        (data.lastDispensedDate && data.ispreferred)? "card drug-card-green drug-card-brown":
                        (data.isdiscontdate === "YES")? "card drug-card-red":
                        (data.lastDispensedDate)? "card drug-card-green" :
                        (data.ispreferred)? "card drug-card-brown" : "card";
                    k["Drug Name"] = (data.drugname ? data.drugname.toUpperCase() + " " : "") + (data.strength ?
                        (data.strength + "").toUpperCase() + " " : "") + (data.drugformname ? data.drugformname : "");
                    // k["NDC"] = data.drugcode ? data.drugcode : "";
                    k["NDC"] = this._formatsUtil.getNDCFormat(data?.drugcode);
                    k["Dg. Mfg"] = data.manufacturername ? data.manufacturername : "";
                    k["Branded"] = data.isbranded ? "Yes" : "No";
                    k["Pkg. Size"] = data.qtypack ? this.decimalPipe.transform(data.qtypack, '1.3-3') : "0.000";
                    k["AWP"] = data.unitpriceawp ? this.currencyPipe.transform(data.unitpriceawp) : "$0.00";
                    k["Inventory"] = (data.qtyInHand && data.qtyInHand > 0) ? "Yes" : "No";
                    let patchQtyINhand:any;
                    if(data && data.manageinventory){
                        data.manageinventory.map(resp=>{
                            if(resp["BucketId"] === this.bucketId){
                             patchQtyINhand = resp["QtyInHand"];
                            }
                         })
                    }
                    k["Inv. In Hand"] = patchQtyINhand ? this.decimalPipe.transform(patchQtyINhand, '1.3-3') : "0.000"
                    // data.qtyInHand ? this.decimalPipe.transform(data.qtyInHand, '1.3-3') : "0.000";
                    k["Last Dispensed Date"] = data.lastDispensedDate ? moment(data.lastDispensedDate).format("MM/DD/YYYY") : "";
                    k["Preferred"] = data.ispreferred ? "Yes" : "No";
                    k["Brand Name"] = data.drugbandname ? data.drugbandname : "";
                    k["Generic Name"] = data.genericname ? data.genericname : "";
                    k["Specfic Product Id"] = data.therapeuticcode ? data.therapeuticcode : "";
                    k["Therapeutic"] = data.therapeutic ? data.therapeutic : "";
                    k["Unit Price Cost"] = data.unitpricecost ? this.currencyPipe.transform(data.unitpricecost) : "$0.00";
                    k["Last Vendor"] = data.instructions ? data.instructions : "";
                    return k;
                }),
            );
        } else {
            this.equivalentDrugWJSource = new CollectionView([]);
        }
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("equivalentDrugWj");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("equivalentDrugWj", storedWJ);
    }
    openInventoryDetails(item){
        this._inventoryService.getDrugQtyInDifferentBuckets(item.id).pipe(takeUntil(this.unsubscribe$)).subscribe(resp=>{
            if(resp && resp["length"]){
                this.modalRef = this._modalService.open(InventoryDetailsComponent,{
                    centered: true, size: "sm", backdrop: false,
                    keyboard: false, windowClass: "medium-modal-50 .modal-dialog"
                })
                this.modalRef.componentInstance.QtyInfo = resp;
                this.modalRef.componentInstance.IsPopupClosed.pipe(takeUntil(this.unsubscribe$))
                .subscribe(res => {
                    if (this.modalRef) {
                        this.modalRef.close();
                        this.modalRef = null;
                    }
            });
        }else{
            this._alertService.error("The Selected Drug with NDC "+ item.NDC+ " is not being managed by any Inventory Bucket");
        }
        })

    }
    IsNextButtonEnableforFurtherActions(index) {
            if(index === 0 && this.PatientId) { 
                return true;
            }  else if(index === 1 && this.PrescriberId) {
                return true;
            } else if((this.index === 2 && this.DrugId  && !this.isDrugInactive && this.detailsInfo?.RxDaw === 'N') || 
            (!this.DrugId &&
                this.processIdsObj.DrugCount ===
                    0 && this.detailsInfo?.RxDaw === 'N' && this.equivalentList?.length > 0) || this.dawYaddingDrug || (this.detailsInfo?.RxDaw === 'Y' && this.DrugId)) {
                        return true;
            } else {
                return false;
            }
    }
    IsNextButtonDisableforFurtherActions(index, titleMessage: string = '') {
        if(this.IsNextButtonEnableforFurtherActions(this.index)) {
            return false;
        } else if(!titleMessage)
             return this.index < 2 && !((this.index===0 && this.PatientId) || (this.index===1 && this.PrescriberId) || (this.index===2 && this.DrugId && this.isDrugInactive)) 
        || !(this.detailsInfo?.RxDaw === "N" && this.detailsInfo?.RxDaw === "Y");
        else {
                if(!(this.detailsInfo?.RxDaw === "N" || this.detailsInfo?.RxDaw === "Y")) {
                    return "Cannot process this eRx as DAW has invalid value.";
                } else {
                    return "";
                }
        }
    }
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
