import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { constant } from "../app.constants";
import { PatientInfo, PatientFamily, GetFamily } from "../models";
import { Observable } from "rxjs";

const reqOptns = {
    headers: new HttpHeaders().set("isLoadNotReq", "true")
}
@Injectable()
export class PatientService {
    constructor(private _http: HttpClient) { }

    getEditPatientInformation(editPatientId: number): Observable<PatientInfo> {
        return this._http.get<PatientInfo>(
            constant.GET_Patient.replace("{patientID}", "" + editPatientId)
        );
    }

    updatePatientInfo(patientInfo: PatientInfo): Observable<number> {
        return this._http.put<number>(constant.PUT_Patient, patientInfo);
    }

    addPatientInfo(patientInfo: PatientInfo): Observable<number> {
        return this._http.post<number>(constant.POST_Patient, patientInfo);
    }

    saveAllergies(allergInfo: any): Observable<boolean> {
        return this._http.post<boolean>(constant.Allergies, allergInfo);
    }

    getEligibilityTransmissionResults(data): Observable<any> {
        return this._http.post<any>(
            constant.POST_Patient_InsuEligibility,
            data
        );
    }

    deleteAllergies(
        allergyId: number,
        patientId: number,
        allergyCatId
    ): Observable<any> {
        return this._http.delete<any>(
            constant.DELETE_Patient_Allergy.replace(
                "{allergyId}",
                "" + allergyId
            )
                .replace("{patientId}", "" + patientId)
                .replace("{allergyCatId}", "" + allergyCatId)
        );
    }

    addFamilyInfo(familyInfo: PatientFamily): Observable<boolean> {
        return this._http.post<boolean>(
            constant.POST_Patient_Family,
            familyInfo
        );
    }

    getMorepatientInfo(patientId): Observable<any> {
        return this._http.get<any>(
            constant.GET_Patient_MoreInfo.replace("{patientId}", "" + patientId)
        );
    }

    getFamilyInfo(data): Observable<any> {
        return this._http.post<any>(constant.POST_Patient_Family_Search, data);
    }

    deleteFamily(familyId: any) {
        return this._http.delete(
            constant.DELETE_Patient_Family.replace("{familyId}", "" + familyId)
        );
    }

    updateFamily(familyInfo: PatientFamily): Observable<boolean> {
        return this._http.put<boolean>(constant.PUT_Patient_Family, familyInfo);
    }

    checkPatientHasFamily(patId, FamilyId) {
        return this._http.get(constant.GET_Patient_Family_Exist.replace("{patientId}", "" + patId)
            .replace("{patfamilyId}", "" + FamilyId));
    }

    linkFamilyInfo(overRideInfo): Observable<boolean> {
        return this._http.put<boolean>(
            constant.PUT_Patient_Family_Link,
            overRideInfo
        );
    }

    deleteFamilyMember(patId, FamId) {
        return this._http.put(constant.PUT_Remove_Family_Member.replace("{patientId:long}", "" + patId)
            .replace("{familyId:long}", "" + FamId), null);
    }

    getMessagingInfo(patientId: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_Patient_Messaging.replace(
                "{patientId}",
                "" + patientId
            )
        );
    }
    updateMessagingInfo(messageInfo: any): Observable<any> {
        return this._http.put<any>(constant.PUT_Patient_Messaging, messageInfo);
    }

    getClinicalInfo(patientId: any, data: any): Observable<boolean> {
        return this._http.post<any>(
            constant.POST_Patient_ClinicalInfo.replace(
                "{patientId}",
                "" + patientId
            ),
            data
        );
    }

    getRxClinicalInfo(prescNum: any, data: any): Observable<boolean> {
        return this._http.post<any>(
            constant.POST_Rx_ClinicalInfo.replace(
                "{prescnum}",
                "" + prescNum
            ),
            data
        );
    }

    deleteClinicalInfo(clincalInfoId: any, patientId: any) {
        return this._http.delete(
            constant.DELETE_Patient_ClinicalInfo.replace(
                "{clincalInfoId}",
                "" + clincalInfoId
            ).replace("{patientId}", "" + patientId)
        );
    }

    deleteRxClinicalInfo(clincalInfoId: any, prescID: any) {
        return this._http.delete(
            constant.DELETE_Rx_ClinicalInfo.replace(
                "{clincalInfoId}",
                "" + clincalInfoId
            ).replace("{prescId}", "" + prescID)
        );
    }

    getDiagnosisFiltered(diagnosisInfo): Observable<any> {
        return this._http.post<any>(
            constant.POST_Patient_Diagnosis_Search,
            diagnosisInfo
        );
    }

    getPatientDiagnosis(patientId): Observable<any> {
        return this._http.get<any>(
            constant.GET_Patient_Diagnosis.replace(
                "{patientId}",
                "" + patientId
            )
        );
    }

    deletePatientDiagnosis(patientId, diagnosisId): Observable<boolean> {
        return this._http.delete<boolean>(
            constant.DELETE_Patient_Diagnosis.replace(
                "{patientId}",
                "" + patientId
            ).replace("{diagnosisId}", "" + diagnosisId)
        );
    }

    savePatientDiagnosis(info): Observable<number> {
        return this._http.post<number>(constant.POST_Patient_Diagnosis, info);
    }

    getPatientDeliveryAddress(patientId, data): Observable<any> {
        return this._http.post<any>(
            constant.POST_Patient_DeliveryAdd_Search.replace(
                "{patientId}",
                "" + patientId
            ),
            data
        );
    }

    addPatientDeliveryAddress(patientId, data): Observable<number> {
        return this._http.post<number>(
            constant.POST_Patient_DeliveryAdd.replace(
                "{patientId}",
                "" + patientId
            ),
            data
        );
    }

    updatePatientDeliveryAddress(data): Observable<boolean> {
        return this._http.put<any>(constant.PUT_Patient_DeliveryAdd, data);
    }

    deletePatientDeliveryAddress(addressId): Observable<boolean> {
        return this._http.delete<boolean>(
            constant.DELETE_Patient_DeliveryAdd.replace(
                "{addressId}",
                "" + addressId
            )
        );
    }

    setPatientPrimaryAddress(patientId, addressId): Observable<boolean> {
        return this._http.put<any>(
            constant.PUT_Patient_DeliveryAdd_SetPrimaryAdd.replace(
                "{patientId}",
                "" + patientId
            ).replace("{addressId}", "" + addressId),
            null
        );
    }

    getLifeStyleConceptsFiltered(lifeStyleInfo): Observable<any> {
        return this._http.post<any>(
            constant.POST_Patient_LifeStyleConcept_Search,
            lifeStyleInfo
        );
    }

    getPatientLifeStyleConcepts(patientId): Observable<any> {
        return this._http.get<any>(
            constant.GET_Patient_LifeStyleConcept.replace(
                "{patientId}",
                "" + patientId
            )
        );
    }

    deletePatientLifeStyle(lifeStyleId, patientId): Observable<boolean> {
        return this._http.delete<boolean>(
            constant.DELETE_Patient_LifeStyleConcept.replace(
                "{lifeStyleId}",
                "" + lifeStyleId
            ).replace("{patientId}", "" + patientId)
        );
    }

    savePatientLifeStyle(info): Observable<number> {
        return this._http.post<number>(
            constant.POST_Patient_LifeStyleConcept,
            info
        );
    }

    getTrackPatient(patientId, data): Observable<any> {
        return this._http.post<any>(
            constant.POST_Patient_TrackPatient.replace(
                "{patientId}",
                "" + patientId
            ),
            data
        );
    }

    getPatientCustomCategories(patientId): Observable<any> {
        return this._http.get<any>(
            constant.GET_Patient_CustomCat.replace(
                "{patientId}",
                "" + patientId
            )
        );
    }

    getPatientCatData(patCatparam: any): Observable<any> {
        return this._http.post<any>(
            constant.GET_Patient_PatientCategories,
            patCatparam
        );
    }

    savePatientCustomCategories(dataToPost): Observable<number> {
        return this._http.put<number>(
            constant.PUT_Patient_CustomCat,
            dataToPost
        );
    }

    deletePatientCustomCategories(customCatId, patientId): Observable<boolean> {
        return this._http.delete<boolean>(
            constant.DELETE_Patient_CustomCat.replace(
                "{customCatId}",
                "" + customCatId
            ).replace("{patientId}", "" + patientId)
        );
    }

    getPatientClinUnits(dimensionId): Observable<any> {
        return this._http.get<any>(
            constant.GET_Patient_Clinical_Units.replace("{dimensionId}", "" + dimensionId)
        );
    }

    addClinicalInfo(data: any) {
        return this._http.post(constant.POST_Patient_AddClinicalInfo, data);
    }

    addRxClinicalInfo(data: any) {
        return this._http.post(constant.POST_Rx_AddClinicalInfo, data);
    }

    updateClinicalInfo(data: any) {
        return this._http.put(constant.PUT_Patient_ClinicalInfo, data);
    }

    updateRxClinicalInfo(data: any) {
        return this._http.put(constant.PUT_Rx_ClinicalInfo, data);
    }

    transmitPatientInfo(resp, insurerid, policyNum, patientId): Observable<any> {
        return this._http.put(
            constant.PUT_Patient_InsuEligibility_UpdatePatientInsu.replace(
                "{insurerid}",
                "" + insurerid
            )
                .replace("{policyNum}", "" + policyNum)
                .replace("{patientId}", "" + patientId),
            resp
        );
    }

    getPatientInsurance(insuCarrierId: number, patientId: number) {
        return this._http.get(
            constant.GET_Insurance_GetInsurance.replace(
                "{insuCarrierId}",
                "" + insuCarrierId
            ).replace("{patientId}", "" + patientId)
        );
    }

    savePrivacyAcknowledgement(patientID: number, isAcknowledged: boolean) {
        return this._http.put(
            constant.POST_PATIENT_ACKNOWLEDGE.replace(
                "{patientId}",
                "" + patientID
            ).replace(
                "{isPrivacyAcked}",
                "" + isAcknowledged
            ),
            null
        );
    }

    getPriceSchedDrops(): Observable<any> {
        return this._http.get(
            constant.GET_Rx_PriceSchedule
        );
    }

    getDiscPriceSchedDrops(): Observable<any> {
        return this._http.get(
            constant.GET_Rx_DiscountSchedule
        );
    }

    getPatientPatDetails(patId: any) {
        return this._http.get(constant.GET_Patient_PatFamily.replace("{patFamilyId}", "" + patId));
    }

    getEmailValid(data: any) {
        return this._http.post(constant.POST_Email_Valid, data);
    }

    getPatientSearch(data: any) {
        return this._http.post(constant.POST_Patient_Data, data, reqOptns);
    }

    deletePatient(Id: any) {
        return this._http.delete(constant.DELETE_Patient.replace("{patientId}", "" + Id));
    }

    getEligibilityDefaultInus(): Observable<any> {
        return this._http.get(constant.GET_Eligibility_Default_Lists);
    }

    addInsuranceToPatient(dataToPost: any, insuplanId: any) {
        return this._http.post(constant.POST_Eligibility_AddInsuranceToPatient.replace("{insuplanId}", "" + insuplanId), dataToPost);
    }

    getPatientDeleteResponse(patientId: any) {
        return this._http.get(constant.GET_PatientDeleteResponse.replace("{patientId}", "" + patientId));
    }

    getPatientInsuranceData(PatientId: any) {
        return this._http.get(constant.GET_Patient_InsuranceData.replace("{patientId}", "" + PatientId));
    }

    getFileGeneratorList(payload) {
        return this._http.post(constant.GET_FILEGENERATORLIST,payload);
    }

    sendToPacking(payload) {
        return this._http.post(constant.POST_SENDPACKING,payload,{
            responseType: "blob"
        });
    }

    getDiagnosisDetails(diagnosisCode): Observable<any> {
        return this._http.get<any>(
            constant.GET_Diagnosis_Details.replace(
                "{diagnosis}",
                "" + diagnosisCode
            )
        );
    }

    savePatientAllergy(dataToPost): Observable<any> {
        return this._http.post<any>(
            constant.POST_Patient_Allergies,
            dataToPost
        );
    }

    
}
