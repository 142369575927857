import { Component, OnInit, Input, ViewChild, ChangeDetectorRef,Output,EventEmitter } from "@angular/core";
import { RxService, CommonService, AlertService } from "src/app/services";
import { CommonStore, RxStore } from "src/app/store";
import { PrivMaskGuard } from "src/app/guards";
import { AccCategoryE, RefillRxE, SystemData, RxE, PartialCummulativeDetails, ERxDetailsViewModel } from "src/app/models";
import { FormBuilder, FormGroup } from "@angular/forms";
import * as moment from "moment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NRxUtils, PatchRXFGUtil, ColorCode, CommonUtil, WijimoUtil, NRxSaveUtils } from "src/app/utils";
import { Router } from "@angular/router";
import { CollectionView } from "@grapecity/wijmo";
import { Observable, Subject } from "rxjs";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { OverrideLogComponent } from "../override-log/override-log.component";
import { RxTotalQtyComponent } from "../rx-total-qty/rx-total-qty.component";
import { CommonWarnorconfirmPopupComponent } from "../common-warnorconfirm-popup/common-warnorconfirm-popup.component";
import { HoverOver } from "src/app/utils/hover-over.util";
import { ColorCodeRx } from "src/app/utils/rx-color.util";
import { DateCheckUtil } from "src/app/utils/date-checks.util";
import { takeUntil } from "rxjs/operators";
import { EdRxComponent } from "../../ed-rx/ed-rx.component";


@Component({
    selector: "app-refill-flow",
    templateUrl: "./refill-flow.component.html"
})
export class RefillFlowComponent implements OnInit {

    @Input() selectedRx: any;

    @Input() selectedReFillNums: any;

    @Input() FromNavigation: any;

    @Input() IsFromRefill: boolean;

    @Input() refillObj: any;

    @Input() HoldRxQuickRefill :any;
    @Input() RxInfo:any;
    
    @Output() emitHoldRxQckRefill = new EventEmitter<boolean>()


    @ViewChild("FILE", { static: true })
    FILE: any;

    @ViewChild("Refill", { static: true })
    Refill: any;

    @ViewChild("RefillNew", { static: true })
    RefillNew: any;

    @ViewChild("confirmParOrFull", {static: true})
    confirmParOrFull: any;

    @ViewChild("IsDiscontinued", { static: true})
    IsDiscontinued: any;

    @Output()
    RefreshRxHistory = new EventEmitter<any>();

    openOverride: boolean;
    rxrefDetails: any;
    notPickedUp: boolean;
    refDetails: any;
    fileFG: any;
    warnCode: number;
    disableRef: boolean;
    model: any;
    buttonPressed: any;
    SimilarRxs: any;
    prevFilledRx: any;
    TRxQtySelected:any;
    prevActvHeader: string[];
    prevRxHist: CollectionView;
    prevRxSelected: any;
    activeModal: any;
    systemData$: Observable<SystemData>;
    daysCompletedSinceFilled: any;
    refillFlowNo: any;
    refillFlowFrm: any;
    anotherRxNo: any;
    wijimoId: wjcGrid.FlexGrid;
    isNewRefill: boolean;
    filedNew: boolean;
    drugNotAllowPFill: boolean;
    aftrPartialCheck: any;
    FromPrev: any;
    wjHeaders: any;
    partialFillSupplyDays: any;
    reFillSupplyDays: any;
    unsubscribe$: Subject<void> = new Subject();
    currentRefillData: any;
    openpopup: boolean;
    rfData: any;
    largerValuesForDisplay: any;
    accessPrivCate: any;
    rxPrivs: any;

    constructor(private _rxService: RxService, private _fb: FormBuilder, private _modalSvc: NgbModal,
        private _commonStore: CommonStore, private _privMaskGuard: PrivMaskGuard,private _dateCheck: DateCheckUtil,
        private _cdr: ChangeDetectorRef, private _comnUtil: CommonUtil,private _hoverOver: HoverOver,
        public _commonServ: CommonService, private _alertSvc: AlertService, private _nrxUtils: NRxUtils,
        private _router: Router, private _colorCode: ColorCode, private _patchRx: PatchRXFGUtil, private _wijimoUtils: WijimoUtil,      private _colorCodeRx: ColorCodeRx, private _rxStore: RxStore,
        private _nrxSaveUtil: NRxSaveUtils) {
            this.systemData$ = this._commonServ.systemData$;
            this.accessPrivCate = AccCategoryE;
            this.rxPrivs = RxE;
        }

   async ngOnInit() {
        if (this.selectedRx && !this.HoldRxQuickRefill) {
            this.getRxRefillDetails(false,true);
        }
        this.patchDefaultValHeaders();
    this._wijimoUtils.wjSavedData$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(w => {
        if (w && w["WijmoKey"] && w["WijmoKey"] === "refillFlowComponent") {
            this.patchDefaultValHeaders();
        }
    });
    if(this.HoldRxQuickRefill){
        this.refDetails = this.RxInfo;
        this.checkIsHoldRx(); 
    }
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    checkHasPriv(type) {
        this.refillFlowFrm = type;
        if (type === "Refill" || type === "direct") {
            if (!this._privMaskGuard.canActivate(AccCategoryE["Rx"], RxE["RefillRx"])) {
                this.openOveride("Rx", "RefillRx");
            } else {
                this.intiateRefillProcess(type);
                //UI: Handling Overfill Scenarios
                // if((this.refDetails.Prescription.ReFillNum <= this.refDetails.PrescReFill.ReFillNum) && this.rxrefDetails["TotalRemainingQty"] ) {
                //     this.processRefills({PrescNum: this.refDetails.Prescription.PrescNum, "Auth#": this.refDetails.Prescription.ReFillNum , refillNumber: this.refDetails.PrescReFill.ReFillNum,refDetails: this.refDetails});
                // } else {
                   // this.intiateRefillProcess(type);
                // }
            }
        } else {
            if (!this._privMaskGuard.canActivate(AccCategoryE["RefillRx"], RefillRxE["CreateNew"])) {
                this.openOveride("RefillRx", "CreateNew");
            } else {
                    this.intiateRefillProcess(type);
            }
        }
    }
    processRefills(item) {
        const rxHoverOver = this._hoverOver.getRxHoverOver(item?.refDetails, "Rx");
        const rxColorCoding = this._colorCode.showRxColorRefactoring(item?.refDetails,"Rx");
        const modalRef = this._modalSvc.open(
            RxTotalQtyComponent,
            {
                backdrop: false,
                size: "sm",
                windowClass: "mode_width_34 modal-center-to-page",
                keyboard: false
            }
        );
        modalRef.componentInstance.RxNumber = item["PrescNum"];
        modalRef.componentInstance.AuthNumber = item["Auth#"];
        modalRef.componentInstance.RefNumber = item["refillNumber"];
        modalRef.componentInstance.IsFromRefill = true;
        modalRef.componentInstance.FromScreen = this.FromNavigation === "RefQueue" ? "RefQueue" : "RxHistForTotalQuantityRem";
        modalRef.componentInstance.RxStatusObj ={RxHoverOver : rxHoverOver , RxColorCoding : rxColorCoding };
        modalRef.componentInstance.CloseRxTotalQtyComponentPop
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(x => {
            if(x)
               modalRef.close();
               else {
                modalRef.close();
                if(this.model)
                      this.model.close();
               }
        });
    }
    async checkIsPartialFill(type) {
        this.aftrPartialCheck = type;
        if (this.refDetails && this.refDetails.PrescReFill && this.refDetails.PrescReFill.PartialIndStatus === "P") {
            if (this.refDetails.Prescription.ReFillNum <= this.refDetails.PrescReFill.ReFillNum) {
                    this.isNewRefill = false;
                    this.changeRxData(this.aftrPartialCheck);
            } else {
                this.closePartialFillPopup(true);
            }
            //  else if (!this.currentRefillData && type !== "New" && this.refDetails.PrescReFill.StatusId !== 4) {
            //    this.model = this._modalSvc.open(this.confirmParOrFull, {centered: true, backdrop: false, windowClass: "large--content"});
            // } 
        } else {
            this.isNewRefill = (this.refDetails.Prescription.ReFillNum <= this.refDetails.PrescReFill.ReFillNum) ? false : true;
            this.changeRxData(this.aftrPartialCheck);
        }
        if((this.currentRefillData && this.currentRefillData.PrescReFill && this.currentRefillData.PrescReFill.PartialIndStatus === "P")) {
            this.refDetails = this.currentRefillData;
        }
    }

    checkDrugAllowPartial() {
        const drug = this.refDetails["Drug"];
        let result = false;
        if (drug["drugclass"] >= 2 && drug["drugclass"] <= 5) {
            const resp = this._commonServ.controlFileSettings$["source"]["value"];
            if (resp && resp["ControlRefillOptions"] && resp["ControlRefillOptions"].length > 0) {
                const stng = resp["ControlRefillOptions"].find(x => x["DrugClass"] == drug["drugclass"]);
                result = stng && !stng["IsPartialFillAllowed"];
            }
        }
        return result;
    }

    closePartialFillPopup(type) {
        this.isNewRefill = type;
        this.changeRxData(this.aftrPartialCheck);
    }

    intiateRefillProcess(type) {
        if (type !== "direct") {
            if (this.model) {
                this.model.close();
                this.model = null;
            }
        }
        if (type === "Refill" || type === "direct") {
            if (type === "Refill") {
                this.checkPrevOrPresent("Refill");
            } else {
                this.checkIsPartialFill(this.buttonPressed);
            }
        } else {
            this.checkPrevOrPresent("New");
        }
    }

    toggleColorLegend() {
        this._alertSvc.showHelpText({RxStatus: false, DrugName: true, Status: true});
    }

    openOveride(CategoryType, type,endpoint?) {
        const modelRef = this._modalSvc.open(OverrideLogComponent, {
            centered: true,
            keyboard: false,
            backdrop: false
        });
        modelRef.componentInstance.PrivMask = type;
        modelRef.componentInstance.CatId = CategoryType;
        modelRef.componentInstance.Comp = true;
        modelRef.result.then(res => {
            if (res) {
                if (this.model) {
                    this.model.close();
                    this.model = null;
                }
                if (type === "RefillRx") {
                    if (this.refillFlowFrm === "direct") {
                        this.checkIsPartialFill(this.buttonPressed);
                    } else {
                        this.checkPrevOrPresent("Refill");
                    }
                } else {
                    this.checkPrevOrPresent("New");
                }
            } else {
                if (this.refillFlowFrm === "direct") {
                    this.cancelRefill();
                }
            }
        });
    }
    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("refillFlowComponent");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("refillFlowComponent", storedWJ);
    }
    async getRxRefillDetails(FrmPrev?: any, isFromNgonit: boolean = false) {
        this.FromPrev = FrmPrev;
        const rxObj = {
            PrescNum: this.selectedRx, RefillNum: (this.selectedReFillNums || this.selectedReFillNums === 0) ? this.selectedReFillNums : null, IsForPartialFill:
                this.refillObj["IsForPartialFill"]
        };
        this.rxrefDetails = await this._rxService.refillRxDetails(rxObj).toPromise();
        this._rxService.storeRefDetails(this.rxrefDetails);
        this.currentRefillData = (this.rxrefDetails && this.rxrefDetails["CurrentRefillData"]) ? this.rxrefDetails["CurrentRefillData"] : null;
        if (this.rxrefDetails && this.rxrefDetails["Refdetail"] && this.rxrefDetails["Refdetail"]["Refilldetails"].length) {
            let refDet = this.rxrefDetails["Refdetail"]["Refilldetails"];
            if(refDet[0] && this.currentRefillData) { 
                //Based on the EP-3621 this card from APi side we are not getting eRxDetailsViewModel so that we patching  eRxDetailsViewModel from Refill Details.
                this.currentRefillData['eRxDetailsViewModel'] = refDet[0]['eRxDetailsViewModel'];
            }
            if (this.rxrefDetails["Refdetail"]["Refilldetails"].length > 1) {
                const data = this.rxrefDetails["Refdetail"]["Refilldetails"].sort((a: any, b: any) =>
                    (a.PrescReFill.ReFillNum > b.PrescReFill.ReFillNum) || ((a.PrescReFill.ReFillNum === b.PrescReFill.ReFillNum) &&
                        (a.PrescReFill.PartialFillNo > b.PrescReFill.PartialFillNo)) ? -1 : 1);
                refDet = data;
            }
            this.partialFillSupplyDays = this.rxrefDetails["PartialFillSupplyDays"];
            this.reFillSupplyDays = this.rxrefDetails["RefillSupplyDays"];
            this.refDetails = (this.currentRefillData) ? this.currentRefillData : refDet[0];
            this.refillFlowNo = this.refDetails.PrescReFill.Id;
          
            const fillNumber = this._commonServ.checkIsPartialFill
            (this.refDetails.PrescReFill, this.refDetails.Prescription)
            ? this.refDetails.PrescReFill.PartialFillNo : "";
            const getHours = (this.refDetails["PrescReFill"] && this.refDetails["PrescReFill"].FillDtTm) ?
                moment().startOf("day").diff(moment(this.refDetails["PrescReFill"].FillDtTm).startOf("day"), "hours") : null;
            if (isFromNgonit && !(this.IsFromRefill)  &&  getHours && this.refDetails["Drug"] && +this.refDetails["Drug"]["drugclass"] == 2 && getHours > 72 && (fillNumber)) {
                const modelRef = this._modalSvc.open(CommonWarnorconfirmPopupComponent, {
                    backdrop: false,
                    keyboard: false, windowClass: "large--content"
                });
                modelRef.componentInstance.IsHeaderText = "Warning!!";
                modelRef.componentInstance.warnMsg = "Partial fill cannot be filled as it is past 72 hours, filling such prescriptions may lead to an audit.";
                modelRef.componentInstance.okButton = true;
                modelRef.componentInstance.IsPopUpClosed
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((resp: any) => {
                    modelRef.close();
                    this.checkIsDiscontinued();
                });
            } else {
                this.checkIsDiscontinued();
            }

        } else {
            if (this.currentRefillData) {
                this.closePartialFillPopup(false);
            } else {
                this.refDetails = null;
                this.openRefillPopUp(true);
            }
        }
    }
    checlConfirmForClass2Drug() {

    }
    openRefillPopUp(type?: any) {
        if (type) {
            this.model = this._modalSvc.open(this.Refill, { centered: true, backdrop: false,
                 keyboard: false, size: this.refDetails ? "lg" : '' ,  windowClass: !this.refDetails ? "large--content":'' });
        } else {
            if(this.notPickedUp)
                     this.model = this._modalSvc.open(this.RefillNew, { centered: true, backdrop: false,
                 keyboard: false, windowClass: "large--content" });
            else
            this.model = this._modalSvc.open(this.Refill, { centered: true, backdrop: false,
                keyboard: false, windowClass: "large--content" });
        }

    }
    closeConfirmPartialFill() {
            if(this.model) {
                    this.model.close();
                    this.model = null;
            }
    }

    checkIsDiscontinued() {
        if (this.refDetails && this.refDetails.Prescription && this.refDetails.Prescription.IsDiscontinued ) {
            this.model = this._modalSvc.open(this.IsDiscontinued, { centered: true, backdrop: false,
                 keyboard: false, windowClass: "large--content"});
        } else {
            this.FromPrev ? this.checkRxIsFiled() : this.checkIsPickedUp();
        }
    }

    async setOrderStatus() {
        await this._rxService.putRxDiscontinuedOrderStatus(this.refDetails.Prescription.Id).toPromise();
        this.FromPrev ? this.checkRxIsFiled() : this.checkIsPickedUp();
    }

    checkIsPickedUp() {
        if (this.refDetails && this.refDetails.pickupRx && this.refDetails.pickupRx.IsPickedUp) {
            this.checkRxIsFiled();
        } else if (!(this.refDetails.PrescReFill.StatusId === 2 || this.refDetails.Prescription.RxStatusId === 2)
        && this.refDetails.PrescReFill.StatusId !== 4) {
            this.notPickedUp = true;
            this.openRefillPopUp();
        } else {
            this.checkRxIsFiled();
        }
    }

    async checkRxIsFiled() {
        this.notPickedUp = false;
        if (this.refDetails.PrescReFill.StatusId === 2 || this.refDetails.Prescription.RxStatusId === 2) {
            const result = await this._comnUtil.checkCanAssignNewRxFrFile(this.refDetails);
            this.filedNew = result["control"];
            this.fileFG = this._fb.group({
                dueDateTime: moment(new Date()).format("MM/DD/YYYY"),
                createNewRefill: result["filed"]
            });
            const rxExpiredObj = this._colorCodeRx.showRxColorCodeAndHoverOver(this.refDetails, "RxObject", "Rx","hoverOver","withHoverBack");
             if(rxExpiredObj && rxExpiredObj.isDeferredRxExpired) {
                const modelRef = this._modalSvc.open(CommonWarnorconfirmPopupComponent, {
                    backdrop: false,
                    keyboard: false, windowClass: "large--content"
                });
                modelRef.componentInstance.warnMsg = rxExpiredObj.hoverOverDisplay;
                modelRef.componentInstance.okButton = true;
                modelRef.componentInstance.IsPopUpClosed
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((resp: any) => {
                    modelRef.close();
                    this.cancelRefill();
                });
             } else 
                     this._modalSvc.open(this.FILE, { centered: true, backdrop: false});
        } else {
            this.checkRefOptions();
        }
    }

    async checkRefOptions() {
        const orderDate = moment(this.refDetails.PrescReFill.OrderDtTm);
        const today = moment();
        this.warnCode = 0;
        const daysBeforeCreated = today.diff(orderDate, "days");
        this.rxrefDetails.MaxOrderToLastrefilldays = this.rxrefDetails.MaxOrderToLastrefilldays
        || this.rxrefDetails.MaxOrderToLastrefilldays === 0
            ? this.rxrefDetails.MaxOrderToLastrefilldays : 365;
        this.rxrefDetails["MAxRefillAllowed"] = this.rxrefDetails["MAxRefillAllowed"] || this.rxrefDetails["MAxRefillAllowed"] === 0
        ? this.rxrefDetails["MAxRefillAllowed"] : 99;
        if(this.rxrefDetails.TotalRemainingQty <= 0){
            this.warnCode = 2;
        } else if (daysBeforeCreated < this.rxrefDetails.MaxOrderToLastrefilldays &&
            this.rxrefDetails.FinalExpdate > -this.rxrefDetails.MaxOrderToLastrefilldays &&
            this.rxrefDetails.FinalExpdate < 0) {
                this.daysCompletedSinceFilled = this.rxrefDetails.FinalExpdate < 0 ?
                -this.rxrefDetails.FinalExpdate :  this.rxrefDetails.FinalExpdate;
            this.warnCode = 1;
        } else if (this.rxrefDetails.FinalExpdate > 0 && daysBeforeCreated < this.rxrefDetails.MaxOrderToLastrefilldays
            && !(this.rxrefDetails.TotalRemainingQty)) {
            this.warnCode = 2;
        }
        const fillNo = this.refDetails.PrescReFill.PartialFillNo ? this.refDetails.PrescReFill.PartialFillNo : 1;
        
        this.refDetails.islatestpartialrefill  = this.refDetails && this.refDetails.partialCummulativeDetails ?
        (this.refDetails.partialCummulativeDetails["TotFills"] - fillNo === 0) ? 1 : 0 : 1;

        if ((this.refDetails.PrescReFill.ReFillNum >= this.rxrefDetails["MAxRefillAllowed"] &&
        (!(this.rxrefDetails.TotalRemainingQty))) && !this.currentRefillData) {
            this.warnCode = 7;
        }
        if ((this.refDetails.PrescReFill.StatusId === 4 || this.refDetails.Prescription.RxStatusId === 4) && this.refDetails.isRxTransferred) {
            this.warnCode = 4;
        }
        if ((this.refDetails.Prescription.ReFillNum <= this.refDetails.PrescReFill.ReFillNum) && (!(this.rxrefDetails["TotalRemainingQty"])) || ((this.refDetails["Drug"]["drugclass"] >= 2 && this.refDetails["Drug"]["drugclass"] <= 5) && this.rxrefDetails.TotalRemainingQty > 0 && (this.refDetails.PrescReFill.ReFillNum === this.refDetails.Prescription.ReFillNum))) {
            this.warnCode = 5;
        }  
        // if((this.refDetails.Prescription.ReFillNum <= this.refDetails.PrescReFill.ReFillNum) && this.rxrefDetails["TotalRemainingQty"]) {
        //     this.warnCode = 10;
        // }
        if (this.rxrefDetails["TotalRemainingQty"] && ((daysBeforeCreated > this.rxrefDetails.MaxOrderToLastrefilldays) ||
            (this.rxrefDetails.FinalExpdate < -this.rxrefDetails.MaxOrderToLastrefilldays) || (this.refDetails.RxExpirationDate && this._dateCheck.checkExpiryDate(this.refDetails.RxExpirationDate))) ) {
            this.warnCode = 6;
        }
        if (this.warnCode) {
            if (!(this.warnCode === 1 || this.warnCode === 10)) {
                this.disableRef = true;
            }

            await this.getPatientDrugDisHistory(this.refDetails.Prescription.PatientId, this.refDetails.Prescription.DrugId);
        } else {
            this.disableRef = false;
            this.SimilarRxs = null;
        }
        if (this.warnCode && !this.currentRefillData) {
            this.openRefillPopUp("lg");
        } else if(this.currentRefillData) {
            this.aftrPartialCheck = "Refill";
            this.closePartialFillPopup(false);
        } else {
            this.checkIsHoldRx();
        }
    }

    async getPatientDrugDisHistory(patientID, drugId) {
      this.SimilarRxs = await this._rxService.getPatientDrugDispHist(patientID, drugId).toPromise();
      if (this.SimilarRxs && this.SimilarRxs["dispensedHistory"] && this.SimilarRxs["dispensedHistory"].length > 0) {
        this.generatePatDisWJ(this.SimilarRxs["dispensedHistory"]);
      }
    }

    selectedPrevRef(checked, item) {
        if (checked) {
            this.anotherRxNo = item["rx#"];
            this.prevRxSelected = item["PrescRefillId"];
            this.TRxQtySelected = Math.floor(item["T.Rx Qty Rem"]);  
            item["IsSelected"] = true;
            this._cdr.detectChanges();
            if (item["Ref#"] === item["Auth"] || (!(this.warnCode === 1 || this.warnCode === 2) &&
            this.prevRxSelected === this.refillFlowNo) || (!this.TRxQtySelected)) {
                this.disableRef = true;
            } else {
                this.disableRef = false;
            }
            this.generatePatDisWJ(this.SimilarRxs["dispensedHistory"], "AlreadyDefined");
            this.wijimoId.refresh();
        } else {
            checked = false;
            this.anotherRxNo = null;
            item["IsSelected"] = false;
            if (!(this.warnCode === 1 || this.warnCode === 2)) {
                this.disableRef = true;
            } else {
                this.disableRef = false;
            }
            this.prevRxSelected = null;
            this._cdr.detectChanges();
            this.generatePatDisWJ(this.SimilarRxs["dispensedHistory"], "AlreadyDefined");
            this.wijimoId.refresh();
        }
    }

    fillSimilarFiled() {
        this.selectedRx = this.prevFilledRx["PrescNum"];
        this.getRxRefillDetails(true);
    }

    checkPrevOrPresent(type) {
        if (this.prevRxSelected && this.prevRxSelected !== this.refDetails.PrescReFill.Id && type === "Refill") {
            this.selectedRx = this.anotherRxNo;
            this.prevRxSelected = null;
            this.anotherRxNo = null;
            this.getRxRefillDetails(true);
        } else {
            this.checkIsPartialFill(type);
        }
    }

    init(flex: wjcGrid.FlexGrid) {
        this.wijimoId = flex;
        this.wijimoId.refresh();
        this.wijimoId.refreshCells(true);
        this._cdr.detectChanges();
    }

    generatePatDisWJ(info: any, type?: any) {
        if (info && info.length > 0) {
            if (!(type && type === "AlreadyDefined")) {
                this.prevRxSelected = this.refDetails.PrescReFill.Id;
            }
            this.prevRxHist = new CollectionView(
                info.map((d, i) => {
                    const j = {};
                    j["Rx#"] = d.Prescription.PrescNum ? d.Prescription.PrescNum.toString() : null;
                    j["rx#"] = d.Prescription.PrescNum;
                    j["Ref#"] = d.PrescReFill.ReFillNum;
                    j["PrescRefillId"] = d.PrescReFill.Id;
                    j["Auth#"] = d.Prescription.ReFillNum;
                    j["Fill#"] = this._commonServ.checkIsPartialFill(d.PrescReFill, d.Prescription
                    ) ?
                    d.PrescReFill.PartialFillNo : " ";
                    j["Fill Date"] = moment(d.PrescReFill.FillDtTm).format("MM/DD/YYYY");
                    j["Status"] = (d.PrescReFill.StatusId === 4 || d.Prescription.RxStatusId === 4) ? "T" :
                    (d.PrescReFill.StatusId === 2 || d.Prescription.RxStatusId === 2 || d.RxBill.StatusId === 6) ? "D" :
                    (d.PrescReFill.StatusId === 3 || d.Prescription.RxStatusId === 3) && d.RxBill.StatusId === 2 ? "BI" :
                    (d.RxBill.StatusId === 2 && d.PrescReFill.StatusId !== 4 && d.Prescription.RxStatusId !== 2
                    && d.Prescription.RxStatusId !== 4 && d.PrescReFill.StatusId !== 2) ? "B" :
                ((d.PrescReFill.StatusId === 3 || d.Prescription.RxStatusId === 3) && d.RxBill.StatusId === 1) ? "UI" : "U";
                    j["Drug Name"] = d.Drug.drugname + d.Drug.strength + d.Drug.drugformname;
                    j["Qty"] = d.PrescReFill.DispQty ? d.PrescReFill.DispQty.toFixed(3) : "0.000";
                    j["Days"] = d.PrescReFill.OrderDays;
                    j["Prescriber"] = (d.Prescriber && d.Prescriber.fullname)? d.Prescriber.fullname.replace("_", " , ") : null;
                    let primaryInsu = null;
                    if (d.Patient.insurance && d.Patient.insurance.length > 1) {
                        primaryInsu = (d.Patient.insurance).filter(val => val["insuranceid"] === +d.RefillInsu.InsuId);
                    } else  if (d.Patient.insurance && d.Patient.insurance.length === 1) {
                        primaryInsu = d.Patient.insurance;
                    }
                    j["Ins.Type"] = primaryInsu && primaryInsu.length > 0 ? primaryInsu[0].insuType : "CASH";
                    j["IsBilled"] = this._comnUtil.checkIsGreenBilled(d, this.systemData$["source"]["value"]);
                    if (this.prevRxSelected && (d.PrescReFill.Id === this.prevRxSelected)) {
                        j["IsSelected"] = true;
                    } else {
                        j["IsSelected"] = false;
                    }
                    j["DrugClass"] = d.Drug.drugclass;
                    j["RxHoverOver"] = this._hoverOver.getRxHoverOver(d, "Rx");
                    j["rxColorCoding"] = this._colorCode.showRxColorRefactoring(d,"Rx");
                    j["IsPicked"] = d.pickupRx ? d.pickupRx.IsPickedUp === true ? true : false : false;
                    j["T.Rx Qty Rem"] = d.TotalRemainingQty ? d.TotalRemainingQty.toFixed(3) : "0.000"; 
                    j["NDC"] = d.Drug.drugcode;
                    return j;
                })
            );
            this.prevFilledRx = this.prevRxHist["items"].find(d => (d.Status).toLowerCase() === "filed")
        }
    }

    itemFormatter = (panel, r, c, cell) => {
        const row = panel.rows[r];
        if (row.dataItem) {
            if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding  ===  "Status") {
                const colors = this._colorCode.showRxStatusColorRefactoring(row.dataItem);
                if(colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "bold";
                cell.style.paddingLeft = "1rem";
            } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Drug Name") {
                const colors = this._colorCode.showDrugColorRefactoring(row.dataItem);
                if(colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "bold";
                cell.style.paddingLeft = "1rem";
            } else {
                cell.style.backgroundColor = "#FFF";
                cell.style.color = "#000";
            }
        }
    }

    openRefillReq(val) {
        this.activeModal = val;
        if (!val) {
            this.cancelRefill();
        }
    }

  async  checkIsHoldRx(type?: any) {
        this.buttonPressed = type ? type : "Refill";
            const rxObj = {
                PrescNum: this.selectedRx, RefillNum: (this.selectedReFillNums || this.selectedReFillNums === 0) ? this.selectedReFillNums : null, IsForPartialFill:
                    this.refillObj["IsForPartialFill"]
            };
            this.rxrefDetails = !this.warnCode ? this._rxStore.rxRefDet$["source"]["value"] : await this._rxService.refillRxDetails(rxObj).toPromise();
            this._rxService.storeRefDetails(this.rxrefDetails);
            const refData = this._comnUtil.getlatestRefillData(this.rxrefDetails);
            if(refData && refData.length){
                this.refDetails = JSON.parse(JSON.stringify(refData[0]));
            }
        if (this.refDetails.PrescReFill.IsRxHold) {
            this.warnCode = 8;
            this.refDetails["PrescReFill"]["IsRxHold"] = false;
            this.openRefillPopUp("lg");
        } else {
            this.checkHasPriv("direct");
        }
    }

    async unHoldRx() {
        const resp = await this.updateRx();
        if(!this.HoldRxQuickRefill){
            if (resp && !isNaN(+resp) && +resp > 0 && this.refDetails) {
                this.refDetails["PrescReFill"]["ModifiedDtTm"] = new Date();
            }
            this.checkHasPriv("direct");
        }
    }

    changeRxData(type) {
       // this.refDetails["RxOtherAmount"] = null;
        this.refDetails["RxPay"] = null;
        this.refDetails["PrescReFill"]["FillDtTm"] = moment(new Date());
        this.refDetails["PrescReFill"]["OrderStatus"] = null;
        this.refDetails["Prescription"]["IsDiscontinued"] = false;
        if (type === "New" || type === "NewFile") {
            this.refDetails["PrescReFill"]["IsNewPrescRefill"] = false;
            this.refDetails["PrescReFill"]["ReFillNum"] = 0;
            this.refDetails["PrescReFill"]["NumberOfFills"] =  type === "New" ? 0 : this.refDetails["PrescReFill"]["NumberOfFills"];
            this.refDetails["TotalRemainingQty"]= 0;
            this.refDetails["PrescReFill"]["PartialFillNo"] = 1;
            this.refDetails["PrescReFill"]["DispQty"] = this.refDetails["PrescReFill"]["OrdQty"];
            this.refDetails["PrescReFill"]["SupplyDays"] = this.refDetails["PrescReFill"]["OrderDays"];
            this.refDetails["PrescReFill"]["Id"] = 0;
            this.rxrefDetails.TotalRemainingQty  = 0;
            this.refDetails["PrescReFill"]["PrescId"] = 0;
            this.refDetails["Prescription"]["Id"] = 0;
            this.refDetails["PrescReFill"]["PartialFillNo"] = 1;
            this.refDetails["RefillInsu"]["RefillId"] = 0
            this.refDetails["PrescReFill"]["ImmunizationId"] = null;
            this.refDetails["drugPickVerif"]= [];
            this.refDetails["rPHVerifs"]= [];
            this.refDetails["drugPickVerificationStatus"] = null;
            this.refDetails["rph1VerificationStatus"] = null;
            this.refDetails["rph2VerificationStatus"] = null;
            this.refDetails["PrescReFill"]["IsEquivalentDisp"] = false;
            this.refDetails["PrescReFill"]["IsIou"] = false;
            if (type !== "NewFile") {
                this.refDetails["PrescReFill"]["OrderDtTm"] = moment(new Date());
                this.refDetails["Prescription"]["PrescNum"] = null;
                this.refDetails["eRxDetailsViewModel"] = new ERxDetailsViewModel();
            }
            this.refDetails["PrescReFill"]["IsHOADefined"] = false;
            this.refDetails["partialCummulativeDetails"] = new PartialCummulativeDetails();
        } else if (type !== "File") {
            this.refDetails["PrescReFill"]["IsNewPrescRefill"] = true;
            if (this.isNewRefill) {
                this.refDetails["PrescReFill"]["ReFillNum"] = 
                (!this.refDetails.PrescReFill.IsOverFillRx  && this.refDetails.Prescription.ReFillNum == this.refDetails.PrescReFill.ReFillNum) ?  this.refDetails["PrescReFill"]["ReFillNum"]  : this.refDetails["PrescReFill"]["ReFillNum"]+1;
                this.refDetails["PrescReFill"]["PartialFillNo"] = 1;
                this.refDetails["PrescReFill"]["DispQty"] = 
                (this.rxrefDetails.TotalRemainingQty < this.refDetails["PrescReFill"]["DispQty"]) ? this.rxrefDetails.TotalRemainingQty : this.refDetails["PrescReFill"]["DispQty"];
                this.refDetails["partialCummulativeDetails"] = new PartialCummulativeDetails();
            } else {
                this.refDetails["PrescReFill"]["PartialFillNo"] = this.refDetails["PrescReFill"]["PartialFillNo"] + 1;
                let dispQty = this.refDetails["PrescReFill"]["DispQty"] < this.rxrefDetails.TotalRemainingQty ? this.refDetails["PrescReFill"]["DispQty"] : this.rxrefDetails.TotalRemainingQty;
                this.refDetails["PrescReFill"]["DispQty"] = (!this.refDetails["PrescReFill"]["IsOverFillRx"]) ? dispQty : this.rxrefDetails.TotalRemainingQty;
                this.refDetails["partialCummulativeDetails"]["TotRemQty"] = this.refDetails["partialCummulativeDetails"]["TotRemQty"] - this.refDetails["PrescReFill"]["DispQty"];
            }
        } else {
            this.refDetails["PrescReFill"]["IsNewPrescRefill"] = false;
        }
        if (type !== "File") { // marking rx as non 340b in new refill other than file.
            this.refDetails["PrescReFill"]["Is340B"] = false;
        }
        this.refDetails["PrescReFill"]["StatusId"] = 1;
        this.refDetails["TotalRemainingQty"] = this.rxrefDetails.TotalRemainingQty;
        this.refDetails["Prescription"]["RxStatusId"] = type === "File" && this.refDetails["Prescription"]["RxStatusId"] === 3 ? 3 : 1;
        this.refDetails["RxBill"]["StatusId"] = 1;
        this.refDetails["PrescReFill"]["GroupTransStatus"] = null;
        this.refDetails["RefillInsu"]["InsuPriId"] = 1;
        this.refDetails["PrescReFill"]["IsControlSubmitted"] = null;
        if(type === "Refill") {
            const awpCost = ((this.refDetails.Drug.unitpriceawp ? +this.refDetails.Drug.unitpriceawp
                : 0) * (this.refDetails.PrescReFill.DispQty ? +this.refDetails.PrescReFill.DispQty
                    : 0));
            // this.refDetails["PrescReFill"]["UnitPriceAWP"] = awpCost ? (awpCost).toFixed(2) : "0.00";

            // EP-6870 : Supply days need to carry forward to refills as well even if the sig is not available in the sig file. so commenting this code directly patching the supplydays key in prescRefill. 
            // if(this.isNewRefill) {
            //     this.refDetails["PrescReFill"]["SupplyDays"] = Math.ceil(this.reFillSupplyDays);
            // } else {
            //     this.refDetails["PrescReFill"]["SupplyDays"] = Math.ceil(this.partialFillSupplyDays);
            // }
        }
        this.createAndPatchRxFG(type);
    }

    async createAndPatchRxFG(type?: any) {
        const rxFG = await this._nrxUtils.createPatchStoreRxFG((type === "NewFile" || type === "New") ? null : this.refDetails, "rf");
        if (type === "NewFile") {
            rxFG.controls["isDetOldRx"].patchValue(this.refDetails["Prescription"]["PrescNum"]);
            rxFG.controls["isDetOldRxRf"].patchValue(this.refDetails["PrescReFill"]["ReFillNum"]);
            rxFG.controls["isDetOldRxRfFill"].setValue(this.refDetails["PrescReFill"]["PartialFillNo"]);
            this.refDetails["Prescription"]["PrescNum"] = null;
        } else if (type === "File") {
            rxFG.controls["fileToFill"].patchValue(true);
        }
        if(this.refDetails["RxOtherAmount"]) {
            rxFG.controls["RxOtherAmount"].patchValue(this.refDetails["RxOtherAmount"]);
        }
        rxFG.controls["rxFileType"].patchValue(type);
        rxFG.controls["TreatmentTypeId"].patchValue(this.rxrefDetails["TreatmentTypeId"]);
        this.refDetails['TreatmentTypeId'] = this.rxrefDetails["TreatmentTypeId"];
        rxFG.controls["selectedRxFromRefill"].patchValue(this.selectedRx);
        rxFG.controls["FromNavigationForBenfitCheck"].patchValue(this.FromNavigation);
        this.refDetails["Prescription"]["PrescNum"] = this.refDetails["Prescription"]["PrescNum"] ?
        this.refDetails["Prescription"]["PrescNum"] : rxFG.value["Prescription"]["PrescNum"];
        rxFG.controls["rxType"].patchValue((type === "NewFile" || type === "New" || type === "File") ? "nrf" : "rf");
        const languageCode = this.systemData$["source"]["value"].Lang.filter(
            (person) => person.Name === rxFG.value.Patient.langname
        )[0];
        const sigCodeList: string[] = ("" + rxFG.value.SigCodes.SigCode).toLowerCase().split(" ");
        const altCode = languageCode?.LangCode;
        const sigData = {
            sigcodes: sigCodeList,
            langcodes: ["enhyus", altCode?.replace("-", "hy").toLowerCase()],
        };
        const resp = await this._rxService.getSigCodeListByLangCode(sigData).toPromise();
        if (!Object.keys(resp).length) 
            rxFG.controls["isSupplyDaysChanged"].patchValue(true)
        if(type === "New") rxFG.controls["isNewFrmExisting"].patchValue(true);
        const rxTechType = rxFG.controls["rxType"].value;
        this._patchRx.patchRxInfo(this.refDetails, rxFG, null, rxTechType);
        const otherAmount = (+this.refDetails["TaxAmt"]) + (+this.refDetails["OtherFee"]) + (+this.refDetails["OtherAmount"]);
        rxFG.controls["OtherAmount"].patchValue(otherAmount);
        this._nrxUtils.getCommonEndPoints(this.refDetails,'rf');
            rxFG.controls["rxType"].patchValue((type === "NewFile" || type === "New" || type === "File") ? "nrf" : "rf");
            this._router.navigate(["/eprx/rx"], {
                queryParams: { fm: "nr" }
            });
    }

    async saveFileInfo() {
        const fileV = this.fileFG.getRawValue();
        if (fileV["createNewRefill"]) {
            this.changeRxData("NewFile");
        } else {
            this.changeRxData("File");
        }
    }

    async updateRx() {
        if(this.HoldRxQuickRefill){
            await this._rxService.updateRxInfo(this.refDetails).toPromise();
            this.emitHoldRxQckRefill.emit(true);
        }else{
            return await this._rxService.updateRxInfo(this.refDetails).toPromise();

        }
        
    }

    cancelRefill(isFrm? : any) {
        if(this.HoldRxQuickRefill){
            this.RefreshRxHistory.emit(true);
        }
        this._commonStore.storeRfStage("Pop");
        if(this.FromNavigation === 'Unbilled') {
            this._router.navigate(["eprx/unbilledRx"]);
        } else if(this.FromNavigation === 'RefillQueue') {
            this._router.navigate(["eprx/RefillQueue"]);
        }
    }

    OverrideRes(val) {
        this.openOverride = false;
        if (val) {
            this.intiateRefillProcess(this.refillFlowFrm);
        }
    }

}
